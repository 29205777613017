import styled, { css } from 'styled-components';

import { Div } from '../helpers/StyledUtils';

interface Props {
  xsmall?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;
  xxlarge?: boolean;

  center?: boolean;
  left?: boolean;
}

const Container = styled(Div)<Props>(({
  theme,
  xsmall,
  small,
  medium,
  large,
  xlarge,
  xxlarge,
  center,
  left,
}) => {
  const breakpoint =
    (xsmall && theme.breakpoints[0]) ||
    (small && theme.breakpoints[1]) ||
    (medium && theme.breakpoints[2]) ||
    (large && theme.breakpoints[3]) ||
    (xlarge && theme.breakpoints[4]) ||
    (xxlarge && theme.breakpoints[5]);
  return css`
    margin: ${left ? '0' : '0 auto'};
    width: 100%;
    padding: 0 ${theme.spacing(8)};
    box-sizing: border-box;

    ${center
      ? css`
          text-align: center;
        `
      : ''}

    max-width: ${breakpoint ? theme.pxToRem(breakpoint + 8 * theme.grid_size * 2) : '100%'};
  `;
});

export default Container;
