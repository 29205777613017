import styled, { css } from 'styled-components';

import { Div, StyledUtilsProps } from '../helpers/StyledUtils';
import Icon, { IconName } from './Icon';

export interface AvatarProps extends StyledUtilsProps {
  name?: string;
  icon?: IconName;

  large?: boolean;
  medium?: boolean;
  small?: boolean;
  xsmall?: boolean;
  primary?: boolean;
  neutral?: boolean;
}

export const StyledAvatar = styled(Div)<Omit<AvatarProps, 'name' | 'icon'>>(
  ({ theme, ...props }) => css`
    font-size: ${theme.pxToRem(10)};
    ${theme.optionsToCss(
      {
        large: css`
          height: ${theme.pxToRem(32)};
        `,
        medium: css`
          height: ${theme.pxToRem(24)};
        `,
        small: css`
          height: ${theme.pxToRem(20)};
        `,
        xsmall: css`
          height: ${theme.pxToRem(16)};
        `,
      },
      props,
      'medium',
    )}
    aspect-ratio: 1/1;
    background-color: ${props.neutral
      ? theme.colors.surface.container.hover.neutral
      : theme.colors.surface.base.background_contrast};
    color: ${props.neutral
      ? theme.colors.on.neutral.primary_neutral
      : theme.colors.on.hue.contrast};
    font-weight: ${theme.font_weigths.bold};
    border-radius: ${theme.radius.small};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

const Avatar: React.FC<AvatarProps> = ({ name, icon, ...props }) => {
  return (
    <StyledAvatar {...props}>{icon ? <Icon icon="folder" muted /> : name && name[0]}</StyledAvatar>
  );
};

export default Avatar;
