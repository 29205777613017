import { getCurrentTimezoneAbreviation } from '../../utils/date';
import Text from './base/Text';
import { format } from 'date-fns';

const DisplayDate: React.FC<{ date: string | null; empty_string?: string; size?: 's' | 'm' }> = ({
  date,
  empty_string = '–',
  size = 's',
}) => {
  const text_date = date
    ? `${format(new Date(date), `yyyy-MM-dd HH:mm:ss`)} ${getCurrentTimezoneAbreviation()}`
    : empty_string;
  return (
    <Text mono size={size} muted as="p" m={{ b: 0 }} ellipsis title={text_date}>
      {text_date}
    </Text>
  );
};

export default DisplayDate;
