import { useState } from 'react';
import styled, { css } from 'styled-components';

import { useCopyToClipboard } from '../../utils/copy';
import { ClickableArea } from './base/Button';
import Icon from './base/Icon';
import Text from './base/Text';
import { StyledUtilsProps } from './helpers/StyledUtils';

interface Props extends StyledUtilsProps {
  value: string;
  valueElement?: React.ReactNode;
  secret?: boolean;
  mono?: boolean;
}

const StyledCopyableFieldValue = styled(ClickableArea)(
  ({ theme }) => css`
    padding: ${({ theme }) => theme.spacing(1.5)} ${({ theme }) => theme.spacing(3)};
    background-color: ${theme.colors.surface.base.disabled};
    border-radius: ${theme.radius.normal};
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    min-width: 0;
    border: 0;
    box-shadow: ${theme.shadows.disabled.base};

    &:hover {
      background-color: ${theme.colors.surface.container.hover.neutral};
      border-color: ${theme.colors.outline.hover.neutral_variant};
    }

    p,
    button {
      display: inline-block;
    }

    button {
      line-height: 1;
    }
  `,
);

const CopyableField: React.FC<Props> = ({
  value,
  secret = false,
  mono,
  valueElement,
  ...props
}) => {
  const [show_secret, setShowSecret] = useState(false);
  const copyToClipboard = useCopyToClipboard();

  return (
    <StyledCopyableFieldValue {...props} onClick={() => copyToClipboard(value)}>
      {valueElement ? (
        valueElement
      ) : (
        <Text
          as="p"
          ellipsis
          m={0}
          mono={mono}
          onMouseOver={() => secret && setShowSecret(true)}
          onMouseOut={() => secret && setShowSecret(false)}>
          {secret && !show_secret
            ? `${value?.substring(0, 3)}${value?.substring(3).replace(/./g, '*')}`
            : value}
        </Text>
      )}
      <Icon icon="copy" right pointer />
    </StyledCopyableFieldValue>
  );
};

export default CopyableField;
