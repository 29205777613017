import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import useSWR from 'swr';

import {
  CLIDestinationTypeConfig,
  HTTPDestinationTypeConfig,
} from '../../../../typings/Destination.interface';
import APIMethodKeys from '../../dashboard/client/APIMethodKeys';
import Avatar from '../../dashboard/components/common/base/Avatar';
import Badge from '../../dashboard/components/common/base/Badge';
import Button, { ButtonGroup, ClickableArea } from '../../dashboard/components/common/base/Button';
import { StyledCard, StyledCardSection } from '../../dashboard/components/common/base/Card';
import Divider from '../../dashboard/components/common/base/Divider';
import Icon from '../../dashboard/components/common/base/Icon';
import Text from '../../dashboard/components/common/base/Text';
import Tooltip from '../../dashboard/components/common/base/Tooltip';
import { useDialog } from '../../dashboard/components/common/Dialog';
import Dropdown from '../../dashboard/components/common/Dropdown';
import DropdownMenu from '../../dashboard/components/common/DropdownMenu';
import TextInput from '../../dashboard/components/common/Form/Fields/TextInput';
import { Div } from '../../dashboard/components/common/helpers/StyledUtils';
import Modal from '../../dashboard/components/common/Modal';
import { useToasts } from '../../dashboard/components/common/Toast';
import SigninForm from '../../dashboard/components/scenes/SigninScene/form';
import { capitalizeFirstLetter } from '../../dashboard/utils';
import { copyTextToClipboard } from '../../dashboard/utils/copy';
import field_formats from '../../dashboard/utils/field-formatters';
import { ConsoleContext } from './App';
import { ConsoleGlobalContext } from './context/ConsoleGlobalContext';
import { ConsoleResourceContext } from './context/ConsoleResourceContext';
import { ConsoleSidebarContext } from './context/ConsoleSidebarContext';
import DiscoverCards from './DiscoverCards';
import SampleWebhooks from './EventSection/SampleWebhooks';

const StyledNav = styled(Div)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    border-bottom: ${theme.border};
    z-index: ${theme.zindex.fixed};
    background-color: ${theme.colors.surface.base.background};
  `,
);

const StyledLogo = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${theme.spacing(2)};
    margin-right: ${theme.spacing(4)};
    color: ${theme.colors.on.neutral.primary};

    svg {
      fill: currentColor;
    }
  `,
);

const Nav: React.FC<{ context: ConsoleContext; onClose?: () => void }> = ({ context, onClose }) => {
  const { HookdeckAPI, user, logout } = useContext(ConsoleGlobalContext);
  const { source, webhook, setSource, deleteSource } = useContext(ConsoleResourceContext);
  const { editDestination, closeSidebar } = useContext(ConsoleSidebarContext);
  const [show_signup, setShowSignup] = useState(false);
  const [edit_source, setEditSource] = useState<boolean | null>(false);

  const showDialog = useDialog();
  const { addToast } = useToasts();

  const theme = useTheme();

  const { data: sources, mutate } = useSWR(
    APIMethodKeys.sources.list({ limit: 250, dir: 'asc' }),
    () => HookdeckAPI.sources.list({ limit: 250, dir: 'asc' }),
  );

  const { data: cli_sessions } = useSWR(
    webhook &&
      webhook.destination &&
      webhook.destination.type === 'CLI' &&
      APIMethodKeys.cli_clients.getSessions({
        active: true,
        webhook_ids: [webhook.id],
        limit: 1,
      }),
    () =>
      HookdeckAPI.cli_clients.getSessions({
        active: true,
        webhook_ids: [webhook.id],
        limit: 1,
      }),
  );

  const is_online = cli_sessions !== undefined && cli_sessions.length > 0;

  const createSource = () => {
    let source_number = 1;
    while (source_number < 250) {
      if (sources?.models.find((s) => s.name === `Source-${source_number}`)) {
        source_number++;
      } else {
        break;
      }
    }
    HookdeckAPI.sources
      .create({ name: `Source-${source_number}` })
      .then((source) => {
        setSource(source);
        mutate((data) => {
          if (!data) {
            return {
              pagination: {},
              count: 1,
              models: [source],
            };
          }
          return { ...data, models: [...data.models, source] };
        });
      })
      .then(() => {
        addToast('success', 'New source created');
        setEditSource(true);
      })
      .catch(() => addToast('error', 'Failed to create source'));
  };

  const updateSource = (name: string): void => {
    HookdeckAPI.sources
      .update(source.id, { name })
      .then((source) => {
        setSource(source);
        addToast('success', 'Source updated');
        mutate((data) => {
          if (!data) {
            return {
              pagination: {},
              count: 1,
              models: [source],
            };
          }
          return {
            ...data,
            models: data.models.map((model) => (model.id === source.id ? source : model)),
          };
        });
      })
      .catch((e) => addToast('error', 'Failed to update source'));
  };

  const onSourceDelete = async () => {
    showDialog(
      () => {
        HookdeckAPI.sources
          .delete(source.id)
          .then(() => {
            mutate((data) => {
              if (!data) {
                return {
                  pagination: {},
                  count: 0,
                  models: [],
                };
              }
              return {
                ...data,
                models: data.models.filter((model) => model.id !== source.id),
              };
            });
            deleteSource();
          })
          .catch(() => {
            addToast('error', 'Failed to delete source');
          });
      },
      undefined,
      {
        title: 'Are you sure you want to delete this source?',
        message: `You will lose all associated events and data.`,
        danger: true,
      },
    );
  };

  return (
    <StyledNav flex={{ justify: 'space-between' }} p={2}>
      <Div flex={{ align: 'center' }}>
        {context === 'fullpage' && (
          <StyledLogo>
            <svg width="24" height="16" viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1919 5.68085C18.3207 5.68085 18.4495 5.77681 18.5139 5.90476C18.5783 6.03272 18.5461 6.19266 18.4495 6.28862L10.4012 14.2857C8.17991 16.4929 4.54209 16.4929 2.32078 14.2857C0.0994552 12.0785 0.0994552 8.46383 2.32078 6.25663L6.95657 1.65031C8.27649 0.338784 10.0793 -0.17303 11.7855 0.0508886C11.9143 0.082877 12.0109 0.146853 12.0753 0.274806C12.1075 0.40276 12.0753 0.530715 11.9787 0.62668L10.9485 1.65031C10.7875 1.81025 10.5944 1.90621 10.4012 1.9382C9.6286 2.06615 8.88816 2.41803 8.30868 2.99382L3.67288 7.60015C2.192 9.07161 2.192 11.4707 3.67288 12.9422C5.15376 14.4137 7.56824 14.4137 9.04912 12.9422L14.8117 7.21628L13.8781 6.28862C13.7815 6.19266 13.7493 6.03272 13.8137 5.90476C13.8781 5.77681 14.0069 5.68085 14.1356 5.68085H18.1919ZM9.88614 10.3192C10.0149 10.3192 10.1437 10.2232 10.2081 10.0952C10.2725 9.96728 10.2403 9.80734 10.1437 9.71138L9.21008 8.78372L14.9726 3.05779C16.4535 1.58633 18.868 1.58633 20.3489 3.05779C21.8298 4.52926 21.8298 6.92839 20.3489 8.39986L15.6809 13.0062C15.1014 13.582 14.361 13.9338 13.5883 14.0618C13.363 14.0938 13.1698 14.1898 13.0411 14.3497L12.0109 15.3733C11.9143 15.4693 11.8821 15.5972 11.9143 15.7252C11.9465 15.8531 12.0753 15.9491 12.204 15.9491C13.9103 16.173 15.7131 15.6612 17.033 14.3497L21.6688 9.74337C23.8901 7.53617 23.8901 3.92148 21.6688 1.71428C19.4475 -0.492915 15.8097 -0.492915 13.5883 1.71428L5.54008 9.71138C5.4435 9.80734 5.41131 9.96728 5.47569 10.0952C5.54008 10.2232 5.66885 10.3192 5.79762 10.3192H9.88614Z" />
            </svg>
            <Div m={{ x: 1 }} />
            <svg width="58" viewBox="0 0 50 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.605469 5.47599C0.605469 4.62666 0.813469 3.84232 1.22947 3.12299C1.65414 2.40366 2.22614 1.83599 2.94547 1.41999C3.6648 0.995323 4.44914 0.78299 5.29847 0.78299C6.16514 0.78299 6.9668 1.00832 7.70347 1.45899C8.44014 1.90099 9.0078 2.49032 9.40647 3.22699L7.91147 4.01999C7.65147 3.56066 7.28747 3.19232 6.81947 2.91499C6.35147 2.62899 5.84447 2.48599 5.29847 2.48599C4.76114 2.48599 4.25847 2.62032 3.79047 2.88899C3.33114 3.15766 2.96714 3.52166 2.69847 3.98099C2.4298 4.44032 2.29547 4.93866 2.29547 5.47599C2.29547 6.01332 2.4298 6.51166 2.69847 6.97099C2.96714 7.43032 3.33114 7.79432 3.79047 8.06299C4.25847 8.33166 4.76114 8.46599 5.29847 8.46599C5.8618 8.46599 6.37314 8.32299 6.83247 8.03699C7.30047 7.75099 7.66447 7.36966 7.92447 6.89299L9.41947 7.71199C9.0208 8.45732 8.45314 9.05532 7.71647 9.50599C6.9798 9.94799 6.1738 10.169 5.29847 10.169C4.44914 10.169 3.6648 9.96099 2.94547 9.54499C2.22614 9.12032 1.65414 8.54832 1.22947 7.82899C0.813469 7.10966 0.605469 6.32532 0.605469 5.47599Z"
                fill={theme.colors.on.neutral.primary_neutral}
              />
              <path
                d="M13.4673 10.182C12.8433 10.182 12.267 10.0303 11.7383 9.72699C11.2183 9.41499 10.8023 8.99899 10.4903 8.47899C10.187 7.95032 10.0353 7.37832 10.0353 6.76299C10.0353 6.14766 10.187 5.57999 10.4903 5.05999C10.8023 4.53132 11.2183 4.11532 11.7383 3.81199C12.267 3.49999 12.8433 3.34399 13.4673 3.34399C14.0827 3.34399 14.6503 3.49999 15.1703 3.81199C15.6903 4.11532 16.102 4.53132 16.4053 5.05999C16.7173 5.57999 16.8733 6.14766 16.8733 6.76299C16.8733 7.37832 16.7173 7.95032 16.4053 8.47899C16.102 8.99899 15.6903 9.41499 15.1703 9.72699C14.6503 10.0303 14.0827 10.182 13.4673 10.182ZM13.4543 8.56999C13.7837 8.56999 14.087 8.49199 14.3643 8.33599C14.6417 8.17132 14.8627 7.95032 15.0273 7.67299C15.192 7.39566 15.2743 7.09232 15.2743 6.76299C15.2743 6.43366 15.192 6.13032 15.0273 5.85299C14.8627 5.57566 14.6417 5.35466 14.3643 5.18999C14.087 5.02532 13.7837 4.94299 13.4543 4.94299C13.125 4.94299 12.8217 5.02532 12.5443 5.18999C12.267 5.35466 12.046 5.57566 11.8813 5.85299C11.7253 6.13032 11.6473 6.43366 11.6473 6.76299C11.6473 7.09232 11.7253 7.39566 11.8813 7.67299C12.046 7.95032 12.267 8.17132 12.5443 8.33599C12.8217 8.49199 13.125 8.56999 13.4543 8.56999Z"
                fill={theme.colors.on.neutral.primary_neutral}
              />
              <path
                d="M21.2363 3.33099C21.6176 3.33099 22.0033 3.41332 22.3933 3.57799C22.7833 3.73399 23.1169 4.01999 23.3943 4.43599C23.6803 4.85199 23.8233 5.41099 23.8233 6.11299V9.99999H22.2113V6.11299C22.2113 5.62766 22.0726 5.28099 21.7953 5.07299C21.5266 4.85632 21.2103 4.74799 20.8463 4.74799C20.4909 4.74799 20.1833 4.87799 19.9233 5.13799C19.6719 5.38932 19.5463 5.79666 19.5463 6.35999V9.99999H17.9343V3.49999H19.5463V4.04599C19.7803 3.82932 20.0403 3.65599 20.3263 3.52599C20.6123 3.39599 20.9156 3.33099 21.2363 3.33099Z"
                fill={theme.colors.on.neutral.primary_neutral}
              />
              <path
                d="M26.8104 5.31999C26.8104 5.50199 26.9101 5.64066 27.1094 5.73599C27.3174 5.82266 27.6424 5.91366 28.0844 6.00899C28.5871 6.11299 29.0074 6.22999 29.3454 6.35999C29.6834 6.48999 29.9738 6.69799 30.2164 6.98399C30.4678 7.26132 30.5934 7.63832 30.5934 8.11499C30.5934 8.53966 30.4634 8.90799 30.2034 9.21999C29.9521 9.53199 29.6098 9.77032 29.1764 9.93499C28.7518 10.091 28.2881 10.169 27.7854 10.169C27.1094 10.169 26.5244 10.039 26.0304 9.77899C25.5451 9.51899 25.1854 9.14632 24.9514 8.66099L26.2774 7.97199C26.4941 8.56999 26.9968 8.86899 27.7854 8.86899C28.1234 8.86899 28.4138 8.80399 28.6564 8.67399C28.9078 8.54399 29.0334 8.35766 29.0334 8.11499C29.0334 7.90699 28.9208 7.75532 28.6954 7.65999C28.4788 7.55599 28.1234 7.44332 27.6294 7.32199C27.1181 7.20066 26.7021 7.07932 26.3814 6.95799C26.0608 6.83666 25.7834 6.65032 25.5494 6.39899C25.3154 6.13899 25.1984 5.79666 25.1984 5.37199C25.1984 4.95599 25.3154 4.59632 25.5494 4.29299C25.7834 3.98099 26.0954 3.74266 26.4854 3.57799C26.8841 3.41332 27.3218 3.33099 27.7984 3.33099C28.3618 3.33099 28.8688 3.44799 29.3194 3.68199C29.7701 3.91599 30.1168 4.24966 30.3594 4.68299L29.0464 5.34599C28.8211 4.86066 28.4051 4.61799 27.7984 4.61799C27.4951 4.61799 27.2524 4.68299 27.0704 4.81299C26.8971 4.93432 26.8104 5.10332 26.8104 5.31999Z"
                fill={theme.colors.on.neutral.primary_neutral}
              />
              <path
                d="M34.9097 10.182C34.2857 10.182 33.7094 10.0303 33.1807 9.72699C32.6607 9.41499 32.2447 8.99899 31.9327 8.47899C31.6294 7.95032 31.4777 7.37832 31.4777 6.76299C31.4777 6.14766 31.6294 5.57999 31.9327 5.05999C32.2447 4.53132 32.6607 4.11532 33.1807 3.81199C33.7094 3.49999 34.2857 3.34399 34.9097 3.34399C35.5251 3.34399 36.0927 3.49999 36.6127 3.81199C37.1327 4.11532 37.5444 4.53132 37.8477 5.05999C38.1597 5.57999 38.3157 6.14766 38.3157 6.76299C38.3157 7.37832 38.1597 7.95032 37.8477 8.47899C37.5444 8.99899 37.1327 9.41499 36.6127 9.72699C36.0927 10.0303 35.5251 10.182 34.9097 10.182ZM34.8967 8.56999C35.2261 8.56999 35.5294 8.49199 35.8067 8.33599C36.0841 8.17132 36.3051 7.95032 36.4697 7.67299C36.6344 7.39566 36.7167 7.09232 36.7167 6.76299C36.7167 6.43366 36.6344 6.13032 36.4697 5.85299C36.3051 5.57566 36.0841 5.35466 35.8067 5.18999C35.5294 5.02532 35.2261 4.94299 34.8967 4.94299C34.5674 4.94299 34.2641 5.02532 33.9867 5.18999C33.7094 5.35466 33.4884 5.57566 33.3237 5.85299C33.1677 6.13032 33.0897 6.43366 33.0897 6.76299C33.0897 7.09232 33.1677 7.39566 33.3237 7.67299C33.4884 7.95032 33.7094 8.17132 33.9867 8.33599C34.2641 8.49199 34.5674 8.56999 34.8967 8.56999Z"
                fill={theme.colors.on.neutral.primary_neutral}
              />
              <path
                d="M40.9379 8.16699C40.9379 8.30566 40.9682 8.40532 41.0289 8.46599C41.0982 8.52666 41.2065 8.55699 41.3539 8.55699C41.4579 8.55699 41.5965 8.54399 41.7699 8.51799V9.99999C41.4492 10.052 41.1502 10.078 40.8729 10.078C40.3702 10.078 39.9845 9.94799 39.7159 9.68799C39.4559 9.41932 39.3259 8.94699 39.3259 8.27099V0.89999H40.9379V8.16699Z"
                fill={theme.colors.on.neutral.primary_neutral}
              />
              <path
                d="M45.8785 3.33099C46.5025 3.33099 47.0615 3.48699 47.5555 3.79899C48.0581 4.10232 48.4481 4.51399 48.7255 5.03399C49.0028 5.55399 49.1415 6.13032 49.1415 6.76299C49.1415 6.97099 49.1241 7.16166 49.0895 7.33499H44.0585C44.1711 7.77699 44.4008 8.13232 44.7475 8.40099C45.0941 8.66099 45.5145 8.79099 46.0085 8.79099C46.3291 8.79099 46.6325 8.72166 46.9185 8.58299C47.2131 8.44432 47.4341 8.26666 47.5815 8.04999L48.8165 8.77799C48.5131 9.22866 48.1188 9.57532 47.6335 9.81799C47.1568 10.052 46.6065 10.169 45.9825 10.169C45.2891 10.169 44.6738 10.0217 44.1365 9.72699C43.5991 9.43232 43.1831 9.02499 42.8885 8.50499C42.5938 7.98499 42.4465 7.39999 42.4465 6.74999C42.4465 6.10866 42.5895 5.52799 42.8755 5.00799C43.1701 4.48799 43.5775 4.08066 44.0975 3.78599C44.6261 3.48266 45.2198 3.33099 45.8785 3.33099ZM47.6205 6.07399C47.5598 5.65799 47.3691 5.33299 47.0485 5.09899C46.7278 4.85632 46.3291 4.73499 45.8525 4.73499C45.5231 4.73499 45.2198 4.79566 44.9425 4.91699C44.6738 5.03832 44.4571 5.20299 44.2925 5.41099C44.1278 5.61899 44.0411 5.83999 44.0325 6.07399H47.6205Z"
                fill={theme.colors.on.neutral.primary_neutral}
              />
            </svg>
          </StyledLogo>
        )}
        <ButtonGroup>
          <Dropdown
            warp_with_card={false}
            outline
            label={source.name}
            onToggle={() => {
              setEditSource(null);
            }}>
            <StyledCard>
              <StyledCardSection style={{ minWidth: '250px' }} p={{ y: 1, x: 3 }} muted>
                <Text semi size="xs" text_wrap={false}>
                  Sources
                </Text>
              </StyledCardSection>
              <StyledCardSection style={{ overflow: 'visible' }}>
                {sources?.models
                  .filter((source) => !source.disabled_at)
                  .map((model) => {
                    return (
                      <Div key={model.id} flex>
                        <ClickableArea
                          onClick={() => {
                            setEditSource(null);
                            setSource(model);
                          }}
                          p={{ l: 3, y: 2, r: 12 }}>
                          <Text semi flex={{ align: 'center' }}>
                            {model.id === source.id ? (
                              <Icon primary icon="success_circle" left={2} />
                            ) : (
                              <Div p={{ l: 6 }} />
                            )}
                            {model.name}
                          </Text>
                        </ClickableArea>
                        {model.id === source.id && (
                          <div style={{ position: 'relative' }}>
                            <Div style={{ position: 'absolute', right: 0 }} m={1}>
                              <Button
                                small
                                invisible
                                onClick={() => {
                                  setEditSource(!edit_source);
                                }}
                                icon="settings"
                              />
                            </Div>
                            {edit_source && (
                              <StyledCard
                                raised={2}
                                style={{
                                  position: 'absolute',
                                  left: '8px',
                                  right: 0,
                                  width: '300px',
                                }}>
                                <StyledCardSection
                                  muted
                                  flex={{ justify: 'space-between', align: 'center' }}
                                  p={1}>
                                  <Text semi size="xs" m={{ l: 2 }}>
                                    Source Settings
                                  </Text>
                                  <Button
                                    invisible
                                    small
                                    icon="close"
                                    onClick={() => setEditSource(false)}
                                  />
                                </StyledCardSection>
                                <StyledCardSection>
                                  <Formik
                                    initialValues={{ name: source.name }}
                                    validate={async (values) => {
                                      const errors: { name?: string } = {};
                                      if (!values.name || values.name.length === 0) {
                                        errors.name = 'Required';
                                      } else if (source.name !== values.name) {
                                        const used = await HookdeckAPI.sources.nameIsUsed(
                                          values.name,
                                        );
                                        if (used) {
                                          errors.name = 'Name is already used.';
                                        }
                                      }
                                      return errors;
                                    }}
                                    onSubmit={(values) => updateSource(values.name)}>
                                    {({ isValid }) => (
                                      <Form>
                                        <Div p={4}>
                                          <TextInput
                                            label="Name"
                                            name="name"
                                            format={field_formats.slugify}
                                            placeholder={`https://example.com`}
                                            required
                                          />
                                          <Button submit primary block disabled={!isValid}>
                                            Update
                                          </Button>
                                        </Div>
                                      </Form>
                                    )}
                                  </Formik>
                                  <Divider m={{ x: 4 }} />
                                  <Div p={4}>
                                    <Div>
                                      <Text size="s" semi as="p" m={{ b: 0 }}>
                                        Delete Source
                                      </Text>
                                      <Text muted size="s" as="p" m={{ b: 2 }}>
                                        Delete this source and all associated data.
                                      </Text>
                                    </Div>
                                    <Div>
                                      <Button block danger onClick={onSourceDelete}>
                                        Delete
                                      </Button>
                                    </Div>
                                  </Div>
                                </StyledCardSection>
                              </StyledCard>
                            )}
                          </div>
                        )}
                      </Div>
                    );
                  })}
                <Divider />
                <ClickableArea
                  onClick={() => {
                    createSource();
                  }}
                  p={{ y: 2, x: 3 }}>
                  <Text semi flex={{ align: 'center' }}>
                    <Icon muted icon="add_circle" left={2} />
                    New Source
                  </Text>
                </ClickableArea>
              </StyledCardSection>
            </StyledCard>
          </Dropdown>
          <Tooltip placement="bottom-start" tooltip="Copy URL">
            <Button
              neutral
              onClick={async () => {
                await copyTextToClipboard(source.url);
                addToast('success', 'Copied to clipboard');
              }}>
              {source.url?.replace('https://', '').replace('http://', '')}
            </Button>
          </Tooltip>
        </ButtonGroup>
        <Icon icon="arrow_forward" m={{ x: 2 }} />
        <ButtonGroup flex>
          {!webhook?.destination ? (
            <Tooltip tooltip="Send events to HTTP or Localhost" placement="bottom-start">
              <Button neutral onClick={editDestination}>
                <Icon icon="add_circle" small left muted />
                <Text semi muted>
                  Add Destination
                </Text>
              </Button>
            </Tooltip>
          ) : (
            <Tooltip placement="bottom-start" tooltip="View destination settings">
              <Button
                neutral
                icon={webhook?.destination?.type === 'HTTP' ? 'http' : 'terminal'}
                flex={{ align: 'center' }}
                onClick={() => editDestination()}>
                <Text semi ellipsis style={{ maxWidth: '232px' }}>
                  {(webhook?.destination?.type === 'HTTP' ||
                  !!(webhook?.destination?.config as HTTPDestinationTypeConfig)?.url
                    ? (webhook?.destination?.config as HTTPDestinationTypeConfig).url
                    : (webhook?.destination?.config as CLIDestinationTypeConfig)?.path) || ''}
                </Text>
                <Icon icon="settings" small right muted />
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
        {cli_sessions !== undefined ? (
          is_online ? (
            <Badge m={{ l: 2 }} success>
              Connected
            </Badge>
          ) : (
            <Badge m={{ l: 2 }} danger>
              Disconnected
            </Badge>
          )
        ) : null}
      </Div>
      <Div flex={{ align: 'center', gap: 2 }}>
        <Dropdown
          warp_with_card={false}
          placement="bottom-end"
          renderToggle={(open, toggle) => (
            <Tooltip tooltip="Example Webhooks" placement="bottom-end">
              <Button icon="export_notes" neutral onClick={() => toggle(!open)} />
            </Tooltip>
          )}>
          <SampleWebhooks />
        </Dropdown>
        {user.is_guest && show_signup && (
          <Modal title="Sign Up" onClose={() => setShowSignup(false)} size={'medium'}>
            <SigninForm context="signup" redirect={window.location.href} />
          </Modal>
        )}
        {user.is_guest && (
          <Button icon="save" neutral onClick={() => setShowSignup(true)}>
            {context === 'inline' ? 'Save' : 'Save Data'}
          </Button>
        )}
        <DiscoverCards />
        {!user.is_guest && (
          <Dropdown
            renderToggle={(open, toggle) => (
              <ClickableArea rounded p={2} onClick={() => toggle(!open)}>
                <Avatar name={user.name[0]} small />
              </ClickableArea>
            )}>
            <Div p={4}>
              <Text semi>Console Sandbox</Text>
              <Text>{user.email}</Text>
            </Div>
            <Divider />
            <Div p={{ y: 2 }}>
              <ClickableArea
                as="a"
                p={{ x: 4, y: 3 }}
                href={`${VITE_APP_URL}/account-settings`}
                target="_blank">
                <Text>Account Settings</Text>
              </ClickableArea>
              <ClickableArea onClick={logout} p={{ x: 4, y: 3 }}>
                <Text>Logout</Text>
              </ClickableArea>
            </Div>
            <Divider />
            <Div p={{ x: 5, y: 3 }} flex={{ align: 'center', justify: 'space-between' }}>
              <Text m={{ r: 4 }}>Theme</Text>
              <DropdownMenu
                placement="bottom-end"
                outline
                label={capitalizeFirstLetter(theme.mode)}
                options={[
                  { label: 'Dark', onClick: () => theme.toggleTheme('dark') },
                  { label: 'Light', onClick: () => theme.toggleTheme('light') },
                ]}
              />
            </Div>
          </Dropdown>
        )}
        {context === 'embed' && (
          <>
            <Button icon="link" as="a" neutral href={VITE_CONSOLE_URL} target="_blank" />
            <Button
              icon="close"
              onClick={() => {
                closeSidebar();
                onClose && onClose();
              }}
              neutral
            />
          </>
        )}
      </Div>
    </StyledNav>
  );
};

export default Nav;
