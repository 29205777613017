import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { APISource } from '../../../../../typings/Source.interface';
import { APIWebhook } from '../../../../../typings/Webhook.interface';
import APIMethodKeys from '../../../dashboard/client/APIMethodKeys';
import Icon from '../../../dashboard/components/common/base/Icon';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import useLocalStorage from '../../../dashboard/components/hooks/useLocalStorage';
import Sentry from '../Sentry';
import { ConsoleGlobalContext } from './ConsoleGlobalContext';
import { ConsoleSidebarContext } from './ConsoleSidebarContext';

interface ConsoleResourceContextProps {
  source: APISource;
  webhook: APIWebhook;
  setSource: (source: APISource) => void;
  deleteSource: () => void;
  setWebhook: (webhook: APIWebhook) => void;
}

export const ConsoleResourceContext = createContext<ConsoleResourceContextProps>({
  source: {} as APISource,
  webhook: {} as APIWebhook,
  setSource: () => null,
  deleteSource: () => null,
  setWebhook: () => null,
});

let query_source_id: string | null;
try {
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    query_source_id = params.get('source_id');
    params.delete('source_id');
    const new_params_string = params.toString();
    if (query_source_id) {
      let url = window.location.pathname;
      if (new_params_string.length > 0) {
        url += `?${new_params_string}`;
      }
      window.history.replaceState({}, document.title, url);
    }
  }
} catch (e) {
  Sentry.captureException(e);
}

export const ConsoleResourceContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { HookdeckAPI, team } = useContext(ConsoleGlobalContext);
  const { closeSidebar } = useContext(ConsoleSidebarContext);
  const [source, setSource] = useLocalStorage<APISource | null>(
    `${team.id}:console:state:source`,
    null,
  );
  const [webhook, setWebhook] = useLocalStorage<APIWebhook | null>(
    `${team.id}:console:state:webhook`,
    null,
  );
  const [initial_source_id, setQuerySourceId] = useState<string | null>(query_source_id);

  // Get default source
  const { data: sources, mutate } = useSWR(
    APIMethodKeys.sources.list({ limit: 1, id: initial_source_id || source?.id || undefined }),
    () => HookdeckAPI.sources.list({ limit: 1, id: initial_source_id || source?.id || undefined }),
  );

  // If no source, create a default one
  useEffect(() => {
    if (!source && sources?.models?.length === 0 && !initial_source_id) {
      HookdeckAPI.sources
        .create({ name: 'Source' })
        .catch((error) => {
          if (error?.response?.status === 409) {
            return HookdeckAPI.sources.enable(error.response.data.source.id);
          }
          throw error;
        })
        .then((source) => {
          mutate({ ...sources, models: [source] }, false);
          setSource(source);
        });
    } else {
      if (sources?.models?.length) {
        const default_source = sources && sources.models.length > 0 ? sources.models[0] : null;
        setSource(default_source);
      }
      if (source && sources?.models?.length === 0) {
        setSource(null);
      }
    }
    if (sources !== undefined) {
      setQuerySourceId(null);
    }
  }, [sources, HookdeckAPI, mutate]);

  const { data: webhooks } = useSWR(
    source && APIMethodKeys.webhooks.list({ limit: 1, source_id: source.id }),
    () => HookdeckAPI.webhooks.list({ limit: 1, source_id: source?.id }),
  );

  useEffect(() => {
    const default_webhook = webhooks && webhooks.models.length > 0 ? webhooks.models[0] : null;
    if (!webhook) {
      setWebhook(default_webhook);
    }
  }, [webhooks]);

  const context: ConsoleResourceContextProps = {
    source: source || ({} as APISource),
    webhook: webhook || ({} as APIWebhook),
    setWebhook: setWebhook,
    deleteSource: () => {
      setSource(null);
      setWebhook(null);
      closeSidebar();
    },
    setSource: (new_source) => {
      if (source?.id !== new_source.id) {
        setWebhook(null);
      }
      setSource(new_source);
    },
  };

  if (!context.source || !context.webhook) {
    return (
      <Div h={100} flex={{ justify: 'center', align: 'center' }}>
        <Icon icon="loading" muted />
      </Div>
    );
  }

  return (
    <ConsoleResourceContext.Provider value={context}>{children}</ConsoleResourceContext.Provider>
  );
};
