import { Theme } from '@hookdeck/theme';
import 'react';
import styled from 'styled-components';
import type * as CSS from 'csstype';

const transformers = {
  spacing: (value, theme) => (value === 'auto' ? 'auto' : theme.spacing(value)),
  px: (value) => `${value}px`,
  percent: (value) => `${value}%`,
  vh: (value) => `${value}vh`,
  raw: (value) => value,
};

const aliases = {
  p: 'padding',
  m: 'margin',
  f: 'flex',
  g: 'grid',
  h: 'height',
  max_h: 'max_height',
  w: 'width',
  min_w: 'min_width',
  d: 'display',
};

type AttributeMap =
  | [string | string[]]
  | [
      string | string[],
      string | ((value: number | string | boolean, theme: Theme) => string | number),
    ];

type Property = {
  raw: AttributeMap;
  always_apply_raw: boolean;
  attributes: { [key: string]: AttributeMap };
  aliases?: { [key: string]: string };
};

const properties: { [key: string]: Property } = {
  margin: {
    raw: ['margin', transformers.spacing],
    always_apply_raw: false,
    attributes: {
      all: ['margin', transformers.spacing],
      x: [['margin-left', 'margin-right'], transformers.spacing],
      y: [['margin-top', 'margin-bottom'], transformers.spacing],
      top: ['margin-top', transformers.spacing],
      bottom: ['margin-bottom', transformers.spacing],
      left: ['margin-left', transformers.spacing],
      right: ['margin-right', transformers.spacing],
    },
    aliases: {
      l: 'left',
      r: 'right',
      t: 'top',
      b: 'bottom',
    },
  },
  padding: {
    raw: ['padding', transformers.spacing],
    always_apply_raw: false,
    attributes: {
      all: ['padding', transformers.spacing],
      x: [['padding-left', 'padding-right'], transformers.spacing],
      y: [['padding-top', 'padding-bottom'], transformers.spacing],
      top: ['padding-top', transformers.spacing],
      bottom: ['padding-bottom', transformers.spacing],
      left: ['padding-left', transformers.spacing],
      right: ['padding-right', transformers.spacing],
    },
    aliases: {
      l: 'left',
      r: 'right',
      t: 'top',
      b: 'bottom',
    },
  },
  flex: {
    raw: ['display', 'flex'],
    always_apply_raw: true,
    attributes: {
      direction: ['flex-direction'],
      align: ['align-items'],
      justify: ['justify-content'],
      grow: ['flex-grow', (value) => (value ? 1 : 'unset')],
      wrap: ['flex-wrap', (value) => (value ? 'wrap' : 'nowrap')],
      gap: ['gap', transformers.spacing],
    },
  },
  grid: {
    raw: ['display', 'grid'],
    always_apply_raw: true,
    attributes: {
      columns: [
        'grid-template-columns',
        (value) =>
          typeof value === 'number'
            ? `repeat(${value}, minmax(0, 1fr));`
            : typeof value === 'string'
              ? value
              : 'unset',
      ],
      rows: [
        'grid-template-rows',
        (value) =>
          typeof value === 'number'
            ? `repeat(${value}, minmax(0, 1fr));`
            : typeof value === 'string'
              ? value
              : 'unset',
      ],
      gap: ['gap', transformers.spacing],
    },
  },
  height: {
    raw: ['height', transformers.percent],
    always_apply_raw: false,
    attributes: {
      px: ['height', transformers.px],
      pc: ['height', transformers.percent],
      vh: ['height', transformers.vh],
    },
  },
  max_height: {
    raw: ['max-height', transformers.percent],
    always_apply_raw: false,
    attributes: {
      px: ['max-height', transformers.px],
      pc: ['max-height', transformers.percent],
      vh: ['max-height', transformers.vh],
      raw: ['max-height', transformers.raw],
    },
  },
  width: {
    raw: ['width', transformers.percent],
    always_apply_raw: false,
    attributes: {
      px: ['width', transformers.px],
      pc: ['width', transformers.percent],
      raw: ['width', transformers.raw],
    },
  },
  min_width: {
    raw: ['min-width', transformers.percent],
    always_apply_raw: false,
    attributes: {
      px: ['min-width', transformers.px],
      pc: ['min-width', transformers.percent],
      raw: ['min-width', transformers.raw],
    },
  },
  display: {
    raw: ['display'],
    always_apply_raw: false,
    attributes: {},
  },
};

type ValueOf<T> = T[keyof T];

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

type InputKeys = Breakpoint | keyof typeof properties;

type InputValue = number | boolean | string | object;

interface SpacingUtilProps {
  all?: number | 'auto';
  x?: number | 'auto';
  y?: number | 'auto';
  top?: number | 'auto';
  t?: number | 'auto';
  bottom?: number | 'auto';
  b?: number | 'auto';
  left?: number | 'auto';
  l?: number | 'auto';
  right?: number | 'auto';
  r?: number | 'auto';
}

export interface StyledUtilsProps {
  margin?: number | SpacingUtilProps | Partial<{ [key in Breakpoint]: number | SpacingUtilProps }>;
  m?: number | SpacingUtilProps | Partial<{ [key in Breakpoint]: number | SpacingUtilProps }>;
  padding?: number | SpacingUtilProps | Partial<{ [key in Breakpoint]: number | SpacingUtilProps }>;
  p?: number | SpacingUtilProps | Partial<{ [key in Breakpoint]: number | SpacingUtilProps }>;
  width?: number | { [key in Breakpoint]: number };
  h?: number | { px?: number; vh?: number; pc?: number };
  max_h?: number | { px?: number; vh?: number; pc?: number; raw?: string };
  w?: number | { px?: number; vh?: number; pc?: number; raw?: string };
  min_w?: number | { px?: number; vh?: number; pc?: number; raw?: string };
  flex?:
    | boolean
    | {
        direction?: CSS.Property.FlexDirection;
        wrap?: boolean;
        justify?: CSS.Property.JustifyItems;
        align?: CSS.Property.AlignItems;
        grow?: boolean;
        gap?: number;
      };
  grid?: {
    columns?: number | string;
    rows?: number | string;
    gap?: number;
  };
  display?: string | Partial<{ [key in Breakpoint]: string }>;
  d?: string | Partial<{ [key in Breakpoint]: string }>;
  style?: React.CSSProperties;
}

const transformValue = (transform, value, theme) => {
  if (!transform) {
    return value;
  }

  if (typeof transform !== 'function') {
    return transform;
  }
  return transform(value, theme);
};

const attributeToCss = (map: AttributeMap, value: number | boolean | string, theme: Theme) => {
  const [props, transform] = map;
  if (Array.isArray(props)) {
    return props
      .map((prop) => `${prop}: ${transformValue(transform, value, theme)} !important;`)
      .join('\n');
  }
  return `${props}: ${transformValue(transform, value, theme)} !important;`;
};

const attributesToCss = (property: Property, input: ValueOf<StyledUtilsProps>, theme: Theme) => {
  return Object.entries(input ?? {}).reduce((css, [key, value]) => {
    const attribute =
      property.attributes[key] || (property.aliases && property.attributes[property.aliases[key]]);
    if (attribute) {
      css += attributeToCss(attribute, value, theme);
    }
    return css;
  }, '');
};

interface ThemedStyledUtilsProps extends StyledUtilsProps {
  theme: Theme;
}

const breakpoint_index = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  '2xl': 5,
};

export const getStyledUtils = (props: ThemedStyledUtilsProps) => {
  const theme = props.theme;
  return Object.entries(props).reduce(
    (css_string, [attribute, input]: [keyof StyledUtilsProps, ValueOf<StyledUtilsProps>]) => {
      const property = properties[attribute] || properties[aliases[attribute]];

      if (!property) {
        return css_string;
      }

      /* Raw value refers to an input of string, number or bool (not an object, no nested properties) */
      const getRawValueCss = (value: number | string | boolean) =>
        attributeToCss(property.raw, value, theme);

      if (property.always_apply_raw) {
        css_string += getRawValueCss(true);
      }

      if (typeof input !== 'object') {
        return css_string + (input !== undefined ? getRawValueCss(input) : '');
      } else {
        Object.entries(input).forEach(([key, nested_input]: [InputKeys, InputValue]) => {
          if (breakpoint_index[key]) {
            css_string += [
              `@media (min-width: ${
                key === 'xs' ? 0 : theme.breakpoints[breakpoint_index[key]]
              }px) {`,
              typeof nested_input !== 'object'
                ? getRawValueCss(nested_input)
                : attributesToCss(property, nested_input, theme),
              '}',
            ].join('\n');
          }
        });
        css_string += attributesToCss(property, input, theme);
        return css_string;
      }
    },
    '',
  );
};

export const Div = styled.div<StyledUtilsProps>`
  ${(props) => getStyledUtils(props)}
`;

export const Span = styled.span<StyledUtilsProps>`
  ${(props) => getStyledUtils(props)}
`;
