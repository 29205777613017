import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { stringifyQuery } from '../../../utils';
import { default as Alert } from '../../common/base/Alert';
import Button, { ClickableArea } from '../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../common/base/Card';
import Divider from '../../common/base/Divider';
import Icon from '../../common/base/Icon';
import Link from '../../common/base/Link';
import Text from '../../common/base/Text';
import TextInput from '../../common/Form/Fields/TextInput';
import { Div } from '../../common/helpers/StyledUtils';
import { GlobalContext } from '../../contexts/GlobalContext';
import { Promotion, TrackingType } from '../../../typings/Tracking.interface';
import useLocalStorage from '../../hooks/useLocalStorage';

const SigninForm: React.FC<{
  context: 'signup' | 'signin';
  default_error?: string;
  token?: string;
  email?: string;
  redirect?: string;
  organization_slug?: string;
  promotion_slug?: string;
  referrer_slug?: string;
  // Provider NextLink instead of importing to prevent bundling NextJS unnecessarily in the console
  NextLink?: any;
}> = ({
  context,
  default_error,
  token,
  email,
  redirect,
  NextLink,
  organization_slug,
  promotion_slug,
  referrer_slug,
}) => {
  const [error, updateError] = useState<string | null>(default_error || null);
  const [sso, showSSO] = useState<boolean>(false);
  const { HookdeckAPI, configs } = useContext(GlobalContext);
  const theme = useTheme();

  const [is_redirecting, setRedirecting] = useState<boolean>(!!organization_slug);
  const [, setPromotion] = useLocalStorage<Promotion | null>(TrackingType.promotion, null);
  const [, setReferrer] = useLocalStorage<Promotion | null>(TrackingType.referrer, null);

  const label = context === 'signup' ? 'Sign up' : 'Sign in';
  const alt_label = context !== 'signup' ? 'Sign up' : 'Sign in';
  const alt_path = context !== 'signup' ? '/signup' : '/signin';
  const auth_base_path = `${HookdeckAPI.api_url}/${context === 'signup' ? 'signup' : 'signin'}`;

  const query: { invite_token?: string; redirect?: string } = {};
  if (token) {
    query.invite_token = token;
  }
  if (redirect) {
    query.redirect = redirect;
  }

  const querystring = Object.keys(query).length > 0 ? `?${stringifyQuery(query)}` : '';

  useEffect(() => {
    if (promotion_slug) {
      setPromotion({ slug: promotion_slug });
    }
  }, [setPromotion, promotion_slug]);

  useEffect(() => {
    if (referrer_slug) {
      setReferrer({ slug: referrer_slug });
    }
  }, [setReferrer, referrer_slug]);

  useEffect(() => {
    if (organization_slug) {
      HookdeckAPI.auth
        .getSSOUrl(undefined, organization_slug)
        .then(({ url }) => {
          if (url) {
            window.location.href = `${url}?${query}`;
          }
        })
        .catch((e) => {
          setRedirecting(false);
          updateError(error);
        });
    }
  }, [organization_slug, query, error]);

  if (organization_slug && is_redirecting) {
    return (
      <Text muted center>
        Redirecting to your SSO provider...
      </Text>
    );
  }

  if (sso || organization_slug) {
    return (
      <>
        {email && token && (
          <Alert inline info m={{ b: 4 }}>
            Please sign up with{' '}
            <Text as={'span'} bold>
              {email}
            </Text>{' '}
            to join the organization.
          </Alert>
        )}
        <StyledCardSection>
          <Formik<{ email: string }>
            initialValues={{ email: email ?? '' }}
            validate={(values) => {
              const errors: { email?: string } = {};
              if (!values.email || values.email.length === 0) {
                errors.email = 'Required';
              }
              return errors;
            }}
            onSubmit={async (v) => {
              return HookdeckAPI.auth
                .getSSOUrl(v.email, organization_slug)
                .then(
                  ({ url }) =>
                    (window.location.href = `${url}${querystring ? querystring.replace('?', '&') : ''}`),
                )
                .catch((e) => {
                  let error = 'Unexpected error';
                  if (e.response?.message) {
                    error = e.response?.message;
                  } else if (typeof e.response === 'string') {
                    error = e.response;
                  }
                  updateError(error);
                });
            }}>
            <Form>
              <Text bold size="l" as="p">
                Sign-in to continue
              </Text>
              <Text as="p" m={{ b: 5 }}>
                Single Sign-On allows you to connect to Hookdeck using your organization's identity
                provider.
                {!organization_slug && (
                  <>
                    <br />
                    <br />
                    If you are unsure wheter your organization supports Single Sign-On, please
                    contact your us your administrator.
                  </>
                )}
              </Text>
              <TextInput
                label="Work Email"
                name="email"
                auto_complete="email"
                type="email"
                placeholder="john@doe.com"
                required
              />
              <Button submit m={{ t: 3 }} primary block>
                Single Sign-On
              </Button>
              {(error || default_error) && (
                <Div m={{ t: 2 }}>
                  <Alert inline danger>
                    {error || default_error}
                  </Alert>
                </Div>
              )}
              {!organization_slug && (
                <Text center muted m={{ t: 4 }}>
                  {NextLink ? (
                    <NextLink passHref legacyBehavior href={`${alt_path}${querystring}`}>
                      <Link neutral>Cancel</Link>
                    </NextLink>
                  ) : (
                    <Link as="a" neutral href={`${alt_path}${querystring}`}>
                      Cancel
                    </Link>
                  )}
                </Text>
              )}
            </Form>
          </Formik>
        </StyledCardSection>
      </>
    );
  }

  return (
    <>
      {email && token && (
        <Alert inline info m={{ b: 4 }}>
          Please sign up with{' '}
          <Text as={'span'} bold>
            {email}
          </Text>{' '}
          to join the organization.
        </Alert>
      )}
      <StyledCard overflow_hidden raised>
        <StyledCardSection>
          <ClickableArea
            flex={{ align: 'center' }}
            as="a"
            href={`${auth_base_path}/github${querystring}`}
            p={3}
            style={{ borderRadius: 0 }}>
            <Icon
              left
              src={`${configs.APP_URL}/images/github-icon.svg`}
              style={{ filter: theme.mode === 'dark' ? 'invert(1)' : undefined }}
            />
            <Text as="span">
              {label} with <strong>Github</strong>
            </Text>
          </ClickableArea>
        </StyledCardSection>
        <StyledCardSection>
          <ClickableArea
            as="a"
            href={`${auth_base_path}/google${querystring}`}
            p={3}
            style={{ borderRadius: 0 }}>
            <Icon left src={`${configs.APP_URL}/images/google-icon.svg`} />
            <Text as="span">
              {label} with <strong>Google</strong>
            </Text>
          </ClickableArea>
        </StyledCardSection>
        {context === 'signin' && (
          <StyledCardSection>
            <ClickableArea onClick={() => showSSO(true)} p={3} style={{ borderRadius: 0 }}>
              <Icon left icon="lock" />
              <Text as="span">
                {label} with <strong>Single Sign-On</strong>
              </Text>
            </ClickableArea>
          </StyledCardSection>
        )}
      </StyledCard>
      <Divider m={{ y: 6 }} />
      <Formik<{ email: string; password: string; name: string }>
        initialValues={{ email: email ?? '', password: '', name: '' }}
        validate={(values) => {
          const errors: { email?: string; password?: string } = {};
          if (!values.email || values.email.length === 0) {
            errors.email = 'Required';
          }
          if (!values.password || values.password.length === 0) {
            errors.password = 'Required';
          } else if (values.password.length < 8) {
            errors.password = 'Password must contain at least 8 characters';
          }
          return errors;
        }}
        onSubmit={(v) => {
          const promise =
            context === 'signup'
              ? HookdeckAPI.auth.signup(
                  { email: v.email, password: v.password, name: v.name },
                  query,
                )
              : HookdeckAPI.auth.signin({ email: v.email, password: v.password }, query);
          promise
            .then((r) => {
              if (r.redirect) {
                (window as any).location = r.redirect;
              } else {
                (window as any).location.reload();
              }
            })
            .catch((e) => {
              let error = 'Unexpected error';
              if (e.response?.message) {
                error = e.response?.message;
              } else if (typeof e.response === 'string') {
                error = e.response;
              }
              updateError(error);
            });
        }}>
        <Form>
          {context === 'signup' && (
            <TextInput
              label="Name"
              name="name"
              auto_complete="name"
              type="name"
              placeholder="John Doe"
              required
            />
          )}
          <TextInput
            label="Email"
            name="email"
            auto_complete="email"
            type="email"
            placeholder="john@doe.com"
            required
          />
          <TextInput
            type="password"
            label="Password"
            name="password"
            auto_complete="password"
            maxlength={255}
            minlength={8}
            required
            m={{ b: 2 }}
          />
          {context !== 'signup' &&
            (NextLink ? (
              <NextLink legacyBehavior passHref href={`/reset-password`}>
                <Link neutral>Forgot password?</Link>
              </NextLink>
            ) : (
              <Link as="a" href={`/reset-password`} neutral>
                Forgot password?
              </Link>
            ))}
          <Button submit m={{ t: 4 }} primary block>
            {label}
          </Button>
          {(error || default_error) && (
            <Div m={{ t: 2 }}>
              <Alert inline danger>
                {error || default_error}
              </Alert>
            </Div>
          )}
          <Text center muted m={{ t: 3 }}>
            {context !== 'signup' ? "Don't have an account?" : 'Already have an account?'}{' '}
            {NextLink ? (
              <NextLink legacyBehavior passHref href={`${alt_path}${querystring}`}>
                <Link icon="arrow_forward">{alt_label}</Link>
              </NextLink>
            ) : (
              <Link
                as="a"
                href={`${configs.APP_URL}${alt_path}${querystring}`}
                icon="arrow_forward">
                {alt_label}
              </Link>
            )}
          </Text>
        </Form>
      </Formik>
    </>
  );
};

export default SigninForm;
