import styled, { css } from 'styled-components';

import { Div, StyledUtilsProps } from '../helpers/StyledUtils';

type BorderStyle = 'dotted' | 'dashed';

const StyledDivider = styled(Div)<{
  vertical?: boolean;
  primary?: boolean;
  dotted?: boolean;
  dashed?: boolean;
}>`
  background-color: ${({ theme, primary }) =>
    primary ? theme.colors.outline.primary : theme.colors.outline.neutral};
  ${({ vertical }) =>
    vertical
      ? css`
          min-width: 1px;
          align-self: stretch;
        `
      : css`
          min-height: 1px;
          width: 100%;
        `}
  ${({ dotted, dashed, theme, primary, vertical }) => {
    const borderStyle: BorderStyle | null = dotted ? 'dotted' : dashed ? 'dashed' : null;

    return (
      borderStyle &&
      css`
        border-style: ${borderStyle};
        background-color: transparent;
        border-color: ${primary ? theme.colors.outline.primary : theme.colors.outline.neutral};
        border-width: ${vertical ? '0 1px 0 0' : '1px 0 0 0'};
      `
    );
  }}
`;

interface Props extends StyledUtilsProps {
  vertical?: boolean;
  primary?: boolean;
  dotted?: boolean;
  dashed?: boolean;
}

const Divider: React.FC<Props> = (props) => {
  return <StyledDivider {...props} />;
};

export default Divider;
