export function RequestLightIcon() {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.4 9.31666V6.80383C6.4 6.47272 6.2825 6.18933 6.0475 5.95366C5.8125 5.71788 5.53 5.59999 5.2 5.59999H1.4C1.07 5.59999 0.787497 5.48249 0.552497 5.24749C0.317497 5.01249 0.199997 4.72999 0.199997 4.39999V0.999994C0.199997 0.829994 0.257164 0.687494 0.371497 0.572493C0.48583 0.457493 0.627497 0.399994 0.796497 0.399994C0.965497 0.399994 1.10833 0.457493 1.225 0.572493C1.34166 0.687494 1.4 0.829994 1.4 0.999994V4.39999H5.2C5.56 4.39999 5.895 4.4731 6.205 4.61933C6.515 4.76544 6.78 4.96455 7 5.21666C7.22 4.96455 7.485 4.76544 7.795 4.61933C8.105 4.4731 8.44 4.39999 8.8 4.39999H12.6V0.999994C12.6 0.829994 12.6572 0.687494 12.7715 0.572493C12.8858 0.457493 13.0275 0.399994 13.1965 0.399994C13.3655 0.399994 13.5083 0.457493 13.625 0.572493C13.7417 0.687494 13.8 0.829994 13.8 0.999994V4.39999C13.8 4.72999 13.6825 5.01249 13.4475 5.24749C13.2125 5.48249 12.93 5.59999 12.6 5.59999H8.8C8.47 5.59999 8.1875 5.71788 7.9525 5.95366C7.7175 6.18933 7.6 6.47272 7.6 6.80383V9.31666L8.13333 8.78333C8.25555 8.66111 8.39444 8.59999 8.55 8.59999C8.70555 8.59999 8.84444 8.65844 8.96666 8.77533C9.08889 8.90288 9.15 9.04816 9.15 9.21116C9.15 9.37405 9.08889 9.51477 8.96666 9.63333L7.41416 11.1858C7.29361 11.3064 7.15455 11.3667 6.997 11.3667C6.83944 11.3667 6.70155 11.3055 6.58333 11.1833L5.01666 9.61666C4.89444 9.49444 4.83333 9.35555 4.83333 9.19999C4.83333 9.04444 4.89444 8.90555 5.01666 8.78333C5.13889 8.66111 5.27778 8.59999 5.43333 8.59999C5.58889 8.59999 5.72777 8.66111 5.85 8.78333L6.4 9.31666Z"
        fill="currentColor"
      />
    </svg>
  );
}
