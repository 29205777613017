export function ArrowInIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_280_3321"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_280_3321)">
        <path
          d="M9.18333 12.3667C9.06111 12.2444 8.99722 12.1055 8.99167 11.95C8.98611 11.7944 9.0437 11.6563 9.16443 11.5356L10.9 9.79999H5.2C4.87 9.79999 4.5875 9.68249 4.3525 9.44749C4.1175 9.21249 4 8.92999 4 8.59999V2.99999C4 2.82999 4.05716 2.68749 4.17148 2.57249C4.28582 2.45749 4.42748 2.39999 4.59648 2.39999C4.76549 2.39999 4.90833 2.45749 5.025 2.57249C5.14167 2.68749 5.2 2.82999 5.2 2.99999V8.59999H10.9L9.17813 6.86301C9.05938 6.74322 9 6.60277 9 6.44166C9 6.28055 9.05845 6.13888 9.17537 6.01666C9.3029 5.89444 9.44444 5.83333 9.6 5.83333C9.75556 5.83333 9.89311 5.89324 10.0126 6.01308L12.777 8.78434C12.8368 8.84425 12.8806 8.90917 12.9083 8.97908C12.9361 9.04898 12.95 9.12388 12.95 9.20378C12.95 9.28367 12.9361 9.35856 12.9083 9.42846C12.8806 9.49837 12.8389 9.56111 12.7833 9.61666L10.0305 12.3695C9.91017 12.4898 9.76852 12.55 9.60555 12.55C9.4426 12.55 9.30186 12.4889 9.18333 12.3667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
