import { PropsWithChildren, useContext, useRef, useState } from 'react';
import styled, { StyleSheetManager, css } from 'styled-components';
import Button, { StyledButton } from '../../dashboard/components/common/base/Button';

import ThemeProvider from '@hookdeck/theme';
import { createPortal } from 'react-dom';
import { Div } from '../../dashboard/components/common/helpers/StyledUtils';
import useLocalStorage from '../../dashboard/components/hooks/useLocalStorage';
import { ConsoleContext } from './App';
import EventSection from './EventSection';
import Sidebar from './Sidebar';
import { ConsoleGlobalContext } from './context/ConsoleGlobalContext';
import { ConsoleSidebarContext } from './context/ConsoleSidebarContext';

const DEFAULT_SIDEBAR_WIDTH = 500;

const StyledConsoleContextLayout = styled.div<{ context: ConsoleContext }>(
  ({ context }) => css`
    flex-grow: 1;
    display: flex;
    max-height: 100%;
    ${context !== 'inline' &&
    css`
      position: relative;
    `}
  `,
);

const StyledConsoleEventSection = styled.div(
  ({ theme }) => css`
    flex-grow: 1;
    min-width: 50%;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    background-color: ${theme.colors.surface.base.surface};
  `,
);

const StyledConsoleSidebarSection = styled.div<{ overlay: boolean; context: ConsoleContext }>(
  ({ theme, overlay, context }) => css`
    border-left: ${theme.border};
    background-color: ${theme.colors.surface.base.background};
    min-width: ${theme.pxToRem(DEFAULT_SIDEBAR_WIDTH)};
    max-width: 80vw;
    position: ${context === 'inline' ? 'fixed' : 'absolute'};
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    ${overlay &&
    css`
      box-shadow: ${theme.elevation[1]};
    `}

    ${context === 'inline' &&
    css`
      right: ${theme.pxToRem(20)};
      top: ${theme.pxToRem(20)};
      bottom: ${theme.pxToRem(20)};
      border-radius: ${theme.radius.large};
      z-index: ${theme.zindex.fixed + 2};
      box-shadow: ${theme.elevation[3]};
      border: ${theme.border};
      ${StyledSidebarResizeHandle} {
        top: 12px;
        bottom: 12px;
      }
    `}
  `,
);

const StyledSidebarResizeHandle = styled.div<{ resizing: boolean }>(
  ({ theme, resizing }) => css`
    position: absolute;
    top: 0;
    left: -1px;
    bottom: 0;
    width: ${theme.pxToRem(4)};
    z-index: 2;
    cursor: col-resize;
    ${resizing &&
    css`
      border-left: 3px solid ${theme.colors.outline.hover};
    `}
    &:hover {
      border-left: 3px solid ${theme.colors.outline.hover};
    }
  `,
);

const StyledCloseSidebarButton = styled.div(
  ({ theme }) => css`
    position: absolute;

    top: 50%;
    left: -44px;
    bottom: 50%;
    z-index: 4;

    ${StyledButton} {
      box-shadow: ${theme.elevation[1]};
    }
  `,
);

// Need to create a new ShadowDom node for the sidebar to espace the stacking context
// and allow the sidebar to have a z-index that's not relative to the parent
const ShadowDom: React.FC<PropsWithChildren> = ({ children }) => {
  let element = document.getElementById('hookdeck-console-sidebar');
  let section = element?.shadowRoot?.getElementById('root');
  let slot = element?.shadowRoot?.getElementById('app');
  if (!element) {
    element = document.createElement('div');
    element.attachShadow({ mode: 'open' });
    element.id = 'hookdeck-console-sidebar';
    element.style.zIndex = '10000';
    element.style.position = 'fixed';
    document.body.appendChild(element);

    section = element.shadowRoot?.getElementById('root') || document.createElement('html');
    section.id = 'root';
    element.shadowRoot?.appendChild(section);

    slot = element.shadowRoot?.getElementById('app') || document.createElement('div');
    slot.id = 'app';
    section.appendChild(slot);
  }
  return createPortal(
    <StyleSheetManager target={section!}>
      <ThemeProvider is_shadow_node>{children} </ThemeProvider>
    </StyleSheetManager>,
    slot!,
  );
};

let drag_start_position = 0;
let drag_start_width = 0;
const ConsoleContent: React.FC<{}> = () => {
  const { context } = useContext(ConsoleGlobalContext);
  const { sidebar_data, closeSidebar } = useContext(ConsoleSidebarContext);

  const [persisted_height, setPersistedWidth] = useLocalStorage(
    `pref:side_bar:width:${context}`,
    DEFAULT_SIDEBAR_WIDTH,
  );
  const [width, setWidth] = useState(persisted_height);
  const [resizing, setResizing] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null!);
  const containerRef = useRef<HTMLDivElement>(null!);

  const onMouseMove = (e: MouseEvent) => {
    if (!drag_start_position) {
      drag_start_position = e.clientX;
      drag_start_width = sidebarRef?.current?.offsetWidth || 0;
    }
    e.preventDefault();
    const mouse_diff = e.clientX - drag_start_position;
    const new_width = drag_start_width - mouse_diff;
    setWidth(new_width);
    setPersistedWidth(new_width);
  };

  const onDragStart = () => {
    setResizing(true);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onDragEnd);
  };

  const onDragEnd = () => {
    drag_start_position = 0;
    setResizing(false);
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onDragEnd);
  };

  const sidebar_open = !!sidebar_data;

  const overlay =
    context !== 'inline' && containerRef?.current && width > containerRef.current.offsetWidth / 2;

  const sidebar = (
    <StyledConsoleSidebarSection
      style={{ width: width }}
      ref={sidebarRef}
      overlay={overlay}
      context={context}>
      {overlay && (
        <StyledCloseSidebarButton
          onClick={() => {
            setWidth(DEFAULT_SIDEBAR_WIDTH);
            setPersistedWidth(DEFAULT_SIDEBAR_WIDTH);
          }}>
          <Button neutral icon="right_panel_close" small />
        </StyledCloseSidebarButton>
      )}
      <StyledSidebarResizeHandle onMouseDown={onDragStart} resizing={resizing} />
      <Sidebar onClose={closeSidebar} />
    </StyledConsoleSidebarSection>
  );

  return (
    <StyledConsoleContextLayout ref={containerRef} context={context}>
      <StyledConsoleEventSection>
        <EventSection />
      </StyledConsoleEventSection>
      {context !== 'inline' && sidebar_open && (
        <Div
          style={{
            width: `${width + 1}px`,
            maxWidth: '50%',
            minWidth:
              DEFAULT_SIDEBAR_WIDTH > width ? `${DEFAULT_SIDEBAR_WIDTH + 1}px` : `${width + 1}px`,
          }}
        />
      )}
      {sidebar_open && (context === 'inline' ? <ShadowDom>{sidebar}</ShadowDom> : sidebar)}
    </StyledConsoleContextLayout>
  );
};

export default ConsoleContent;
