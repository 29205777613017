import { useContext } from 'react';
import useSWR from 'swr';

import { Event } from '../../../../../typings/Event.interface';
import { Request } from '../../../../../typings/Request.interface';
import { APISource } from '../../../../../typings/Source.interface';
import { APIWebhook } from '../../../../../typings/Webhook.interface';
import APIMethodKeys from '../../../dashboard/client/APIMethodKeys';
import isEmpty from '../../../dashboard/utils/isEmpty';
import { ConsoleGlobalContext } from './ConsoleGlobalContext';

const useConsoleEntries = ({ source, webhook }: { source: APISource; webhook: APIWebhook }) => {
  const { HookdeckAPI } = useContext(ConsoleGlobalContext);

  const default_query_filters = !isEmpty(source) && {
    source_id: source.id,
    include: 'data',
    limit: 250,
    cli_id: { all: true },
  };

  const { data: previous_requests } = useSWR(
    default_query_filters && APIMethodKeys.requests.list(default_query_filters),
    () => {
      return HookdeckAPI.requests.list(default_query_filters);
    },
    {
      refreshInterval: 3000,
    },
  );

  const { data: previous_events, mutate: mutateEvents } = useSWR(
    !isEmpty(webhook) && default_query_filters && APIMethodKeys.events.list(default_query_filters),
    () => HookdeckAPI.events.list(default_query_filters),
    {
      refreshInterval: 3000,
    },
  );

  let merged_entries = [...(previous_events?.models || []), ...(previous_requests?.models || [])]
    // Sort merged entries by created_at
    .sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });

  const requests_id_with_event = merged_entries?.reduce(
    (requests_id_with_event, entry) => {
      if ('request_id' in entry) {
        requests_id_with_event[entry.request_id] = true;
      }
      return requests_id_with_event;
    },
    {} as { [key: string]: boolean },
  );

  const unique_ids = {} as { [key: string]: boolean };

  merged_entries = merged_entries?.reduce(
    (unique_entries, entry) => {
      if (unique_ids[entry.id]) {
        return unique_entries;
      }
      if (!requests_id_with_event[entry.id]) {
        unique_ids[entry.id] = true;
        unique_entries.push(entry);
      }
      return unique_entries;
    },
    [] as (Request | Event)[],
  );

  const is_loading =
    previous_requests === undefined || (!isEmpty(webhook) && previous_events === undefined);

  return {
    entries: !is_loading ? merged_entries?.slice(0, 250) : undefined,
    is_loading: is_loading,
    addEvent: (event: Event): void => {
      mutateEvents((state) => {
        if (!state) {
          return {
            pagination: {},
            count: 1,
            models: [event],
          };
        }
        return { ...state, models: [...state.models, event] };
      });
    },
    mutateEvent: (event: Event): void => {
      mutateEvents((state) => {
        if (!state) {
          return {
            pagination: {},
            count: 1,
            models: [event],
          };
        }
        return {
          ...state,
          models: state.models.map((model) =>
            model.id === event.id ? { ...event, data: model.data } : model,
          ),
        };
      });
    },
  };
};

export default useConsoleEntries;
