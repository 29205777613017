import { colors } from './colors';

/**
 * Anything commented out is a new color token. Figma color naming matches the comments.
 */

const color_tokens = {
  // background colors
  surface: {
    base: {
      background: colors.stone[50], // tan-bg
      surface: colors.white, // bg-0
      variant_surface: colors.neutral[200], // bg-3
      variant_surface_2: colors.neutral[100], // bg-2
      variant_surface_3: colors.neutral[50], // bg-1 - new color token
      background_elevated: colors.white, // bg-elevated - new color token
      background_contrast: colors.black, // bg-contrast - new color token
      primary: colors.blue[500], // primary-blue-bg
      success: colors.green[500], // no match - used on console
      green_histogram: colors.green[300], // green-histogram-bg - new color token
      warning: colors.yellow[500], // primary-yellow-bg
      danger: colors.red[500], // primary-red-bg
      red_histogram: colors.red[500], // red-histogram-bg - new color token
      disabled: colors.neutral[100],
      backdrop: `rgba(0, 0, 0, 0.1)`,
      // States
      hover: {
        neutral: colors.neutral[100], // bg-0-hover
        neutral_variant: colors.neutral[200],
        primary: colors.blue[600], // primary-blue-bg-hover
        danger: colors.red[600], // primary-red-bg-hover
      },
      active: {
        neutral: colors.neutral[200],
        neutral_variant: colors.neutral[400],
        primary: colors.blue[600],
        danger: colors.red[700],
      },
    },
    chart: {
      primary: colors.blue[700],
      danger: colors.red[400],
      success: colors.green[700],
    },
    container: {
      neutral: colors.neutral[200],
      primary: colors.blue[50], // blue-badge-bg
      success: colors.green[50], // success-badge-bg
      warning: colors.yellow[100], // caution-badge-bg
      danger: colors.red[50], // danger-badge-bg
      highlight: colors.yellow[200],
      // States
      hover: {
        neutral: colors.neutral[300],
        primary: colors.blue[100], // blue-badge-bg-hover
        success: colors.green[100],
        warning: colors.yellow[300],
        danger: colors.red[100],
      },
      active: {
        neutral: colors.neutral[400],
        primary: colors.blue[200],
        success: colors.green[200],
        warning: colors.yellow[400],
        danger: colors.red[200],
      },
    },
  },
  // foreground colors
  on: {
    neutral: {
      primary_neutral: colors.black, // neutral-fg-1
      secondary_neutral: colors.stone[700], // neutral-fg-3
      tertiary_neutral: colors.stone[800], // neutral-fg-2
      primary: colors.blue[500], // primary-fg
      danger: colors.red[500], // danger-fg
      warning: colors.yellow[500],
      success: colors.green[500],
      disabled: colors.stone[600], // disabled-fg
      loading: colors.neutral[700],
    },
    code: {
      boolean: colors.blue[600], // code-fg-boolean
      string: colors.green[600], // code-fg-string
      number: colors.yellow[800], // code-fg-integer
      null: colors.red[600], // code-fg-null
    },
    hue: {
      primary: colors.white, // on-hue-fg
      success: colors.white,
      warning: colors.black,
      danger: colors.white,
      contrast: colors.white, // on-contrast-fg - new color token
    },
    hue_container: {
      primary: colors.blue[500], // primary-badge-fg
      primary_variant: colors.blue[300],
      secondary: colors.blue[300],
      success: colors.green[600], // success-badge-fg
      warning: colors.yellow[900], // caution-badge-fg
      danger: colors.red[500], // danger-badge-fg
    },
  },
  outline: {
    neutral: colors.neutral[200], // neutral-outline-1
    neutral_2: colors.neutral[300], // neutral-outline-2
    contrast_line: colors.black, // contrast-line
    primary: colors.blue[100], // primary-badge-outline
    success: colors.green[100], // success-badge-outline
    warning: colors.yellow[300], // caution-badge-outline
    danger: colors.red[100], // danger-badge-outline
    // States
    hover: {
      neutral: colors.neutral[300],
      neutral_variant: colors.neutral[500],
      container_primary: colors.blue[200], // primary-badge-outline-hover
    },
    focus: {
      primary: colors.blue[300], // focused-outline
    },
    active: {
      neutral: colors.neutral[400],
      container_primary: colors.blue[300],
    },
  },
};

const elevation = {
  '1': '0px 1px 0px 0px rgba(0, 0, 0, 0.02)',
  '2': '0px 1px 0px 0px rgba(0, 0, 0, 0.08), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset',
  '3': '0px 8px 24px 0px rgba(82, 80, 74, 0.12)',
};

// Shadows replace elevation tokens
const shadows = {
  primary: {
    base: '0px 0.75px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.32), 0px 0px 0px 1px #0036A3;',
    muted: '0px 1px 2px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.08);',
    focused:
      '0px 1px 2px 0px rgba(0, 0, 0, 0.50), 0px 0px 0px 1px #0036A3, 0px 0.75px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 0px 0px 2px #FFF, 0px 0px 0px 4px #668FE0;',
  },
  neutral: {
    base: '0px 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);',
    muted: '0px 1px 0px 0px rgba(0, 0, 0, 0.4), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset',
    focused: '0px 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);',
  },
  disabled: {
    base: '0px 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);',
  },
  danger: {
    base: '0px 1px 2px 0px #00000029 inset, 0px 0px 0px 1px #CC2314;',
  },
  input: {
    neutral: '0px 1px 2px 0px #00000014 inset, 0px 0px 0px 1px #0000001F;',
  },
};

export default {
  colors: color_tokens,
  elevation,
  shadows,
};
