import 'react';

import styled, { css } from 'styled-components';

import { font_sizes } from '@hookdeck/theme';

import { getStyledUtils, StyledUtilsProps } from '../helpers/StyledUtils';

const type_configs = {
  bold: {
    weight: 'bold',
  },
  semi: {
    weight: 'medium',
  },
  base: {
    weight: 'regular',
  },
  mono: {
    weight: 'regular',
  },
} as const;

interface Props extends StyledUtilsProps {
  as?: string;

  /**
   * @default 'm'
   * @description The size of the text.
   * ```
   * - xs: 12px
   * - s: 13px
   * - m: 14px
   * - l: 16px
   * - xl: 20px
   * - 2xl: 24px
   * ```
   * @example
   * <Text size="s">Small text</Text>
   * <Text size="m">Medium text</Text>
   * <Text size="l">Large text</Text>
   * <Text size="xl">Extra large text</Text>
   * <Text size="2xl">2x large text</Text>
   */
  size?: keyof typeof font_sizes;

  // Font weights
  /**
   * Bold text. Previously known as `heading`.
   */
  bold?: boolean;
  /**
   * Semi-bold text. Previously known as `subtitle`.
   */
  semi?: boolean;
  base?: boolean;
  /**
   * Monospace text. Previously known as `mono`.
   */
  mono?: boolean;

  /**
   * @description tertiary neutral color, neutral-fg-2
   */
  muted?: boolean;
  /**
   * @description secondary neutral color, neutral-fg-3
   */
  neutral?: boolean;
  /**
   * @description danger color, danger-fg
   */
  danger?: boolean;
  /**
   * @description success color, success-fg
   */
  success?: boolean;
  /**
   * @description primary color, primary-fg
   */
  primary?: boolean;
  /**
   * @description warning color, warning-fg
   */
  warning?: boolean;
  /**
   * @description dark color, neutral-fg-1
   */
  dark?: boolean;

  underline?: boolean;
  strike?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
  text_wrap?: boolean | string;
  overflow_wrap?: string;
  word_break?: string;
  center?: boolean;
  align?: string;
  white_space?: string;
  ellipsis?: boolean;
  max_width?: string;
  user_select?: boolean;
  pointer?: boolean;
  cursor_default?: boolean;
}

const Text = styled.div<Props>(
  ({
    theme,
    size = 'm',
    // Text types
    bold,
    semi,
    base,
    mono,

    muted,
    neutral,
    danger,
    primary,
    warning,
    dark,
    success,

    uppercase,
    strike,
    capitalize,
    text_wrap,
    overflow_wrap,
    word_break,
    center,
    align,
    white_space,
    ellipsis,
    max_width,

    user_select = true,
    pointer = false,
    cursor_default = false,
    ...props
  }) => {
    let type = 'base';
    if (bold) {
      type = 'bold';
    } else if (semi) {
      type = 'semi';
    } else if (base) {
      type = 'base';
    } else if (mono) {
      type = 'mono';
    }

    return css`
      ${getStyledUtils({ theme, ...props })}

      ${type === 'mono' &&
      css`
        font-family:
          JetBrains Mono,
          monospace;
        font-size: ${theme.pxToRem(font_sizes.s.font_size)};
        line-height: ${theme.pxToRem(font_sizes.s.line_height)};
      `};

      font-weight: ${theme.font_weigths[type_configs[type].weight]};
      font-size: ${theme.pxToRem(font_sizes[size].font_size)};
      line-height: ${theme.pxToRem(font_sizes[size].line_height)};

      ${muted &&
      css`
        color: ${theme.colors.on.neutral.tertiary_neutral};
      `};
      ${neutral &&
      css`
        color: ${theme.colors.on.neutral.secondary_neutral};
      `};
      ${dark &&
      css`
        color: ${theme.colors.on.neutral.primary_neutral};
      `};

      ${danger &&
      css`
        color: ${theme.colors.on.neutral.danger};
      `};

      ${primary &&
      css`
        color: ${theme.colors.on.neutral.primary};
      `};

      ${warning &&
      css`
        color: ${theme.colors.on.neutral.warning};
      `};

      ${success &&
      css`
        color: ${theme.colors.on.neutral.success};
      `};

      ${center &&
      css`
        text-align: center;
      `};
      ${text_wrap === false &&
      css`
        white-space: nowrap;
      `};
      ${text_wrap &&
      css`
        white-space: ${typeof text_wrap === 'string' ? text_wrap : 'wrap'};
      `};
      ${white_space &&
      css`
        white-space: ${white_space};
      `};
      ${uppercase &&
      css`
        text-transform: uppercase;
      `};
      ${strike &&
      css`
        text-decoration: line-through;
      `};
      ${capitalize &&
      css`
        text-transform: capitalize;
      `};
      ${align &&
      css`
        text-align: ${align};
      `};
      ${overflow_wrap &&
      css`
        overflow-wrap: ${overflow_wrap};
      `};
      ${word_break &&
      css`
        word-break: ${word_break};
      `};
      ${ellipsis &&
      css`
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      `};
      ${max_width &&
      css`
        max-width: ${max_width};
      `};
      ${!user_select &&
      css`
        user-select: none;
        pointer-events: none;
      `};
      ${pointer &&
      css`
        cursor: pointer;
      `};
      ${cursor_default &&
      css`
        cursor: default;
      `};
    `;
  },
);

export default Text;
