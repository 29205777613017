import { useContext } from 'react';
import { APIDestination } from '../../../../../typings/Destination.interface';
import { ShortEventData } from '../../../../../typings/EventData.interface';
import { copyTextToClipboard } from '../../utils/copy';
import { useToasts } from '../common/Toast';
import { GlobalContext } from '../contexts/GlobalContext';

const useCopyASCurl = ({
  type,
  id,
  event_data,
  destination,
}: {
  type?: 'request' | 'event';
  id?: string;
  event_data?: ShortEventData;
  destination?: APIDestination;
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  return async () => {
    const appendTrailingSlash = (url: string, path: string): string => {
      const parts = url.split('?');
      if (parts[0].endsWith('/')) {
        parts[0] = parts[0].slice(0, -1);
      }
      return `${parts[0]}${path}${parts.length > 1 ? parts[0] : ''}`;
    };

    const raw_body =
      type && id && ['event', 'request'].includes(type)
        ? (await HookdeckAPI[`${type}s`].getRawBody(id)).body
        : JSON.stringify(event_data?.body);

    let url = 'https://mock.hookdeck.com';
    if (destination && 'url' in destination.config && destination.config.url) {
      url = destination.config.url;
    } else if (destination && 'path' in destination.config && destination.config.path) {
      url = `http://localhost:[PORT]${destination.config.path}`;
    }
    if (event_data?.path) {
      url = appendTrailingSlash(url, event_data.path);
    }

    if (event_data?.query) {
      url = `${url}?${event_data?.query}`;
    }
    const curl_snippet_lines = [`curl "${url}"`] as string[];
    curl_snippet_lines.push(
      `-X ${(event_data?.headers['x-hookdeck-original-method'] || 'POST').toUpperCase()}`,
    );
    Object.entries(event_data?.headers ?? {}).forEach(([key, value]) => {
      curl_snippet_lines.push(`-H "${key}: ${value}"`);
    });
    if (raw_body) {
      curl_snippet_lines.push(`-d "${raw_body.replace(/"/g, '\\"')}"`);
    }
    const curl_snippet = curl_snippet_lines.join(' \\\n');

    const success = await copyTextToClipboard(curl_snippet);
    if (success) {
      addToast('success', 'Copied cURL command to clipboard');
    }
  };
};

export default useCopyASCurl;
