import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import Icon from './base/Icon';
import Link from './base/Link';
import { Div, StyledUtilsProps } from './helpers/StyledUtils';

interface Props extends StyledUtilsProps {
  onChange: (value: string) => void;
  value: string;
  onBlur?: () => void;
  placeholder?: string;
  name?: string;
  focus?: boolean;
  small?: boolean;
  borderless?: boolean;
  search_ref?: React.RefObject<HTMLInputElement>;
}

const StyledWrapper = styled(Div)<{ borderless: boolean }>(
  ({ theme, borderless }) => css`
    position: relative;
    display: flex;
    align-items: center;

    label {
      position: absolute;
      left: ${theme.spacing(3)};
      margin: 0;
      display: flex;
      align-items: center;
      svg {
        vertical-align: middle;
      }
    }
    button {
      position: absolute;
      padding-right: ${theme.spacing(3)};
      height: 100%;
      right: 0;
    }

    ${borderless &&
    css`
      &:focus-within {
        input {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    `}
  `,
);

const StyledSearchInput = styled.input<{ small?: boolean; borderless: boolean }>(
  ({ theme, small, borderless }) => css`
    width: 100%;
    box-sizing: border-box;
    line-height: ${theme.pxToRem(theme.font_sizes.m.line_height)};
    padding: ${theme.spacing(small ? 0.75 : 1.25)} ${theme.pxToRem(36)};
    ${borderless &&
    css`
      padding: ${theme.spacing(small ? 1.5 : 2.5)} ${theme.pxToRem(36)};
      border: none;
      border-radius: 0;
    `}

    &:focus {
      outline: 1px solid ${theme.colors.outline.focus.primary};
      border-color: ${theme.colors.outline.focus.primary};
    }
  `,
);

const Search = ({
  onChange,
  onBlur,
  placeholder,
  name,
  value,
  focus,
  small,
  borderless,
  search_ref: s_ref,
  ...props
}: Props) => {
  const internal_ref = useRef<HTMLInputElement | null>(null);
  const search_ref = s_ref || internal_ref;

  useEffect(() => {
    if (focus) {
      if (search_ref.current) {
        search_ref.current?.focus({
          preventScroll: true,
        });
      }
    }
  }, [search_ref?.current]);

  return (
    <StyledWrapper {...props} borderless={!!borderless}>
      <label htmlFor={name || 'search'}>
        <Icon icon="search" />
      </label>
      <StyledSearchInput
        borderless={!!borderless}
        small={small}
        ref={search_ref}
        id={name || 'search'}
        placeholder={placeholder || 'Search'}
        value={value || ''}
        onBlur={onBlur}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {value && (
        <Link
          as="button"
          type="button"
          icon="cancel"
          dark
          onClick={() => {
            onChange('');
          }}
        />
      )}
    </StyledWrapper>
  );
};

export default Search;
