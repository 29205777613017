import { IgnoredEvent, IgnoredEventCause } from '../../../../typings/IgnoredEvent.interface';
import { Request, RequestRejectionCauseCompatible } from '../../../../typings/Request.interface';
import { APISource } from '../../../../typings/Source.interface';
import Text from '../components/common/base/Text';

export const rejection_cause_labels = {
  SOURCE_DISABLED: 'Source Disabled',
  NO_CONNECTION: 'No Connection',
  VERIFICATION_FAILED: 'Authentication Failed',
  UNSUPPORTED_HTTP_METHOD: 'Unsupported Method',
  UNSUPPORTED_CONTENT_TYPE: 'Unsupported Content-Type',
  UNPARSABLE_JSON: 'Unparsable JSON',
  PAYLOAD_TOO_LARGE: 'Payload Too large',
  INGESTION_FATAL: 'Ingestion Fatal Error',
  UNKNOWN: 'Unknown error',
};

export const cause_filter_breakdown: Record<
  IgnoredEventCause,
  (ignored_event: IgnoredEvent) => {
    label: string;
    text: string;
  }
> = {
  DISABLED: () => ({
    label: 'Connection Disabled',
    text: 'The connection was disabled at the time the request was received.',
  }),
  FILTERED: (ignored_event) => ({
    label: 'Filtered',
    text: `This request was filtered on ${(ignored_event.meta as string[]).join(
      ', ',
    )} by a filter rule on this connection.`,
  }),
  TRANSFORMATION_FAILED: () => ({
    label: 'Transformation Failed',
    text: `The transformation failed to execute and resulted in a fatal error`,
  }),
  CLI_DISCONNECTED: () => ({
    label: 'CLI Offline',
    text: `No CLI was online when the request was received`,
  }),
};

const rejection_causes_breakdown: Record<
  RequestRejectionCauseCompatible,
  (props: { request: Request; source: APISource }) => {
    text: string | React.ReactElement;
  }
> = {
  SOURCE_DISABLED: ({ source }) => ({
    label: rejection_cause_labels.SOURCE_DISABLED,
    text: `${source.name} is a disabled source. Enable the source.`,
  }),
  NO_CONNECTION: ({ source }) => ({
    label: rejection_cause_labels.NO_CONNECTION,
    text: `${source.name} does not have any connections. Add a connection to the source.`,
  }),
  VERIFICATION_FAILED: () => ({
    label: rejection_cause_labels.VERIFICATION_FAILED,
    text: `The request failed authentication verification. Update your source verification configuration or disable the authentication.`,
  }),
  UNSUPPORTED_HTTP_METHOD: ({ request }) => {
    const method = request?.data?.headers['x-hookdeck-original-method'];
    return {
      label: rejection_cause_labels.UNSUPPORTED_HTTP_METHOD,
      text: (
        <Text as="span">
          The request HTTP method <code>{method}</code> is not enabled on the source associated with
          this request. Enable GET to successfully route this request to Hookdeck.
        </Text>
      ),
    };
  },
  UNSUPPORTED_CONTENT_TYPE: ({ request }) => ({
    label: rejection_cause_labels.UNSUPPORTED_CONTENT_TYPE,
    text: `The request was rejected because it's using an unsupported content type: ${request?.data?.headers['content-type']}.`,
  }),
  UNPARSABLE_JSON: () => ({
    label: rejection_cause_labels.UNPARSABLE_JSON,
    text: `This request has a JSON content-type, but it was rejected because its JSON payload was unparsable. `,
  }),
  PAYLOAD_TOO_LARGE: () => ({
    label: rejection_cause_labels.PAYLOAD_TOO_LARGE,
    text: `This request was rejected because the payload was too large.`,
  }),
  INGESTION_FATAL: () => ({
    label: rejection_cause_labels.INGESTION_FATAL,
    text: `This request failed to be ingested by our system because of unique circumstances. The engineering team has been alerted and an HTTP 500 was returned to the sender in order to trigger any retry logic on their end. We are extremely sorry for the inconvenience and take these errors seriously.`,
  }),
  UNKNOWN: () => ({
    label: rejection_cause_labels.UNKNOWN,
    text: `An unknown error happened.`,
  }),
  // deprecated values
  SOURCE_ARCHIVED: ({ source }) => ({
    label: '',
    text: '',
  }),
  NO_WEBHOOK: ({ source }) => ({
    label: '',
    text: '',
  }),
};

export const getRejectionCauseBreakdown = (cause: RequestRejectionCauseCompatible) => {
  if (cause === 'UNKNOWN' || !rejection_causes_breakdown[cause]) {
    return rejection_causes_breakdown.UNKNOWN;
  }
  return rejection_causes_breakdown[cause];
};
