import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { EventAttemptResponseBody } from '../../../../../../typings/EventAttemptResponse.interface';
import APIMethodKeys from '../../../client/APIMethodKeys';
import HookdeckAPI from '../../../client/Hookdeck';
import { capitalizeFirstLetter } from '../../../utils';
import { copyTextToClipboard } from '../../../utils/copy';
import jsonToGo from '../../../utils/type-extract/go';
import useLocalStorage from '../../hooks/useLocalStorage';
import Button from '../base/Button';
import { StyledCard, StyledCardSection } from '../base/Card';
import Loading from '../base/Loading';
import Text from '../base/Text';
import DropdownMenu from '../DropdownMenu';
import Editor from '../Editor';
import { Div } from '../helpers/StyledUtils';
import JSONBrowser from '../JSONBrowser';
import { useToasts } from '../Toast';
import Search from '../Search';
import Link from '../base/Link';

type Props =
  | {
      label: string;
      body: EventAttemptResponseBody;
      compact?: boolean;
    }
  | {
      label: string;
      body: EventAttemptResponseBody;
      type: 'event' | 'request' | 'bookmark';
      id: string;
      HookdeckAPI: HookdeckAPI;
      compact?: boolean;
    };

const body_options = {
  structured: {
    label: 'Structured',
    icon: 'data_object',
  },
  raw: {
    label: 'Raw',
    icon: 'notes',
  },
  types: {
    label: 'Types',
    icon: 'keyboard',
  },
} as const;

const RawBody: React.FC<{
  loading: boolean;
  body: EventAttemptResponseBody;
  compact?: boolean;
}> = ({ loading, body, compact }) => {
  if (loading) {
    return <Loading />;
  }

  if (body === undefined || body === '' || body === null) {
    return (
      <Text muted capitalize mono size={compact ? 's' : 'm'}>
        {body === '' ? 'Empty' : String(body)}
      </Text>
    );
  }

  return (
    <Text
      as="span"
      white_space={'pre-wrap'}
      word_break={'break-all'}
      mono
      size={compact ? 's' : 'm'}>
      {String(body)}
    </Text>
  );
};

const TypesBody: React.FC<{ types: string | null; compact: boolean }> = ({ types, compact }) => {
  return types ? (
    <Editor
      language="typescript"
      value={types}
      height={`${types.split('\n').length * (compact ? 18 : 20) + 13}px`}
      small_text={compact}
      disabled={true}
      scroll={false}
    />
  ) : (
    <Div flex={{ justify: 'center' }}>
      <Loading />
    </Div>
  );
};

const RequestBody: React.FC<Props> = ({ body, label, compact, ...props }) => {
  const { addToast } = useToasts();
  const [seach_value, setSearchValue] = useState('');
  const [lang, setLang] = useLocalStorage<'typescript' | 'golang'>(
    'pref:body-typings',
    'typescript',
  );
  const [jsonToTS, setJsonToTS] = useState(null);
  const [force_display, setForceDisplay] = useState(false);
  const [body_type, setBodyDisplayType] = useLocalStorage<keyof typeof body_options>(
    'pref:body-display-type',
    'structured',
  );

  let id, HookdeckAPI, type;
  if ('id' in props) {
    id = props.id;
    type = props.type;
    HookdeckAPI = props.HookdeckAPI;
  }

  const is_json = body && typeof body === 'object';

  let resolved_body_type = body_type;
  if (resolved_body_type === 'types' && !is_json) {
    resolved_body_type = 'structured';
  }

  // Resolve raw body
  const fetch_remote_raw_body = resolved_body_type === 'raw' && type && id;

  const { data: raw_body_response } = useSWR(
    fetch_remote_raw_body && APIMethodKeys[`${type}s`].getRawBody(id),
    () => HookdeckAPI[`${type}s`].getRawBody(id),
  );

  useEffect(() => {
    if (!jsonToTS && lang === 'typescript' && resolved_body_type === 'types') {
      import('json-to-ts').then((lib) => setJsonToTS(lib as any));
    }
  }, [lang, resolved_body_type]);

  const options = [
    { ...body_options.structured, onClick: () => setBodyDisplayType('structured') },
    { ...body_options.raw, onClick: () => setBodyDisplayType('raw') },
    ...(is_json ? [{ ...body_options.types, onClick: () => setBodyDisplayType('types') }] : []),
  ];

  let data_to_display: EventAttemptResponseBody = null;
  if (resolved_body_type === 'structured') {
    data_to_display = JSON.stringify(body, null, 2);
  } else if (resolved_body_type === 'raw') {
    data_to_display = fetch_remote_raw_body ? raw_body_response?.body : JSON.stringify(body);
  } else if (resolved_body_type === 'types') {
    data_to_display =
      lang === 'golang'
        ? jsonToGo(JSON.stringify(body), 'Body', true).go
        : jsonToTS && (jsonToTS as any).default(body, { rootName: 'Body' }).join('\n');
  }

  const too_large_to_display =
    data_to_display === 'string'
      ? data_to_display.length > 1_000_000
      : JSON.stringify(body)?.length > 1_000_000;

  return (
    <StyledCard>
      <StyledCardSection flex={{ justify: 'space-between', align: 'center' }} p={{ x: 4, y: 2 }}>
        <Text semi size={compact ? 's' : 'm'}>
          {label}
        </Text>
        <Div flex={{ gap: 2 }}>
          {resolved_body_type === 'structured' && !compact && (
            <Search small value={seach_value} onChange={setSearchValue} />
          )}
          {resolved_body_type === 'types' && (
            <DropdownMenu
              w={{ px: 164 }}
              placement="bottom-end"
              minimal
              small={
                compact
                  ? {
                      text: 's',
                    }
                  : true
              }
              label={capitalizeFirstLetter(lang)}
              options={[
                { label: 'TypeScript', onClick: () => setLang('typescript') },
                { label: 'Golang', onClick: () => setLang('golang') },
              ]}
            />
          )}
          <DropdownMenu
            w={{ px: 164 }}
            label={capitalizeFirstLetter(resolved_body_type)}
            minimal
            small={
              compact
                ? {
                    text: 's',
                  }
                : true
            }
            icon={body_options[resolved_body_type].icon}
            placement="bottom-end"
            options={options}
          />
          <Button
            invisible
            small
            icon="copy"
            onClick={() => {
              copyTextToClipboard(String(data_to_display) as string);
              addToast('success', `Copied to clipboard`);
            }}
          />
        </Div>
      </StyledCardSection>
      {resolved_body_type === 'structured' && compact && (
        <StyledCardSection>
          <Search small borderless value={seach_value} onChange={setSearchValue} />
        </StyledCardSection>
      )}
      <StyledCardSection muted p={resolved_body_type === 'types' ? { y: 1 } : { x: 4, y: 3 }}>
        {too_large_to_display && !force_display ? (
          <Div flex>
            <Text>Payload is more then 1MB and is too large to display properly.</Text>
            <Link m={{ l: 1 }} primary onClick={() => setForceDisplay(true)}>
              Display anyway
            </Link>
          </Div>
        ) : (
          <>
            {resolved_body_type === 'structured' && (
              <JSONBrowser compact={!!compact} search_term={seach_value} json={body as any} />
            )}
            {resolved_body_type === 'raw' && (
              <RawBody
                compact={compact}
                body={data_to_display}
                loading={fetch_remote_raw_body && !raw_body_response}
              />
            )}
            {is_json && resolved_body_type === 'types' && (
              <TypesBody compact={!!compact} types={String(data_to_display)} />
            )}
          </>
        )}
      </StyledCardSection>
    </StyledCard>
  );
};

export default RequestBody;
