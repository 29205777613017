import { useContext } from 'react';
import styled, { css } from 'styled-components';

import Container from '../../../dashboard/components/common/base/Container';
import Divider from '../../../dashboard/components/common/base/Divider';
import Icon from '../../../dashboard/components/common/base/Icon';
import Text from '../../../dashboard/components/common/base/Text';
import CopyableField from '../../../dashboard/components/common/CopyableField';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import { ConsoleGlobalContext } from '../context/ConsoleGlobalContext';
import { ConsoleResourceContext } from '../context/ConsoleResourceContext';
import SampleWebhooks from './SampleWebhooks';

const StyledEmptyStateWrapper = styled(Div)(
  ({ theme }) => css`
    min-height: 100%;
    box-sizing: border-box;
    background-color: ${theme.colors.surface.base.background};
  `,
);

const EmptyState: React.FC = () => {
  const { context } = useContext(ConsoleGlobalContext);
  const { source } = useContext(ConsoleResourceContext);
  const full_page = context === 'fullpage';

  return (
    <>
      <StyledEmptyStateWrapper
        p={full_page ? { x: 10, t: 10, b: 6 } : { all: 8 }}
        flex={{ direction: 'column', align: 'center', justify: full_page ? 'center' : undefined }}>
        <Icon icon="loading" muted />
        <Text
          as="h2"
          bold
          size={full_page ? 'xl' : 'l'}
          m={{ t: full_page ? 3 : 1, b: full_page ? 6 : 1 }}>
          Waiting on your first request
        </Text>
        <Text as="p" size={full_page ? 'm' : 's'} m={{ b: full_page ? 6 : 3 }}>
          Send your HTTP request to the URL provided below
        </Text>
        <Container small>
          <CopyableField mono value={source.url} />
        </Container>
        <Div w={100} style={{ maxWidth: '804px' }} p={{ t: 8, b: 5 }} flex={{ align: 'center' }}>
          <Divider />
          <Text size="s" muted text_wrap={false} m={{ x: 4 }}>
            Send an example webhook
          </Text>
          <Divider />
        </Div>
        <SampleWebhooks />
      </StyledEmptyStateWrapper>
    </>
  );
};

export default EmptyState;
