import { add, startOfDay, startOfHour, startOfMinute, sub } from 'date-fns';

export const time_unit_to_abbreviation = {
  second: 'sec',
  minute: 'min',
  hour: 'hr',
  month: 'mo',
  day: 'd',
  week: 'wk',
  year: 'yr',
  concurrent: 'concurrent',
};

export type RelativeDate =
  | 'last_hour'
  | 'today'
  | 'yesterday'
  | 'last_3_days'
  | 'last_7_days'
  | 'last_2_weeks'
  | 'last_month';

export const relative_dates: {
  [key in RelativeDate]: {
    label: string;
    short_label: string;
    days: number;
    convert: (now: Date) => { min: Date; max: Date };
  };
} = {
  last_hour: {
    label: 'Last hour',
    short_label: '1 hour',
    days: 0,
    convert: (now) => ({
      min: sub(add(startOfMinute(now), { minutes: 1 }), { hours: 1 }),
      max: add(startOfMinute(now), { minutes: 1 }),
    }),
  },
  today: {
    label: 'Today',
    short_label: 'today',
    days: 0,
    convert: (now) => ({
      min: startOfDay(now),
      max: add(startOfHour(now), { hours: 1 }),
    }),
  },
  yesterday: {
    label: 'Last 24 hours',
    short_label: '24 hrs',
    days: 1,
    convert: (now) => ({
      min: sub(add(startOfHour(now), { hours: 1 }), { days: 1 }),
      max: add(startOfHour(now), { hours: 1 }),
    }),
  },
  last_3_days: {
    label: 'Last 3 days',
    short_label: '3 days',
    days: 3,
    convert: (now) => ({
      min: sub(add(startOfHour(now), { hours: 1 }), { days: 3 }),
      max: add(startOfHour(now), { hours: 1 }),
    }),
  },
  last_7_days: {
    label: 'Last 7 days',
    short_label: '7 days',
    days: 7,
    convert: (now) => ({
      min: sub(add(startOfHour(now), { hours: 1 }), { days: 7 }),
      max: add(startOfHour(now), { hours: 1 }),
    }),
  },
  last_2_weeks: {
    label: 'Last 2 weeks',
    short_label: '2 wks',
    days: 14,
    convert: (now) => ({
      min: sub(add(startOfHour(now), { hours: 1 }), { days: 14 }),
      max: add(startOfHour(now), { hours: 1 }),
    }),
  },
  last_month: {
    days: 30,
    label: 'Last month',
    short_label: '1 mo',
    convert: (now) => ({
      min: sub(add(startOfHour(now), { hours: 1 }), { months: 1 }),
      max: add(startOfHour(now), { hours: 1 }),
    }),
  },
};

export const addTimeToDate = (date: Date | string, time: string) => {
  const [h, m, s] = time.split(':');
  const new_date = new Date(date);
  new_date.setHours(parseInt(h ?? '00'), parseInt(m ?? '00'), parseInt(s ?? '00'));
  return new_date;
};

export const getCurrentTimezoneAbreviation = () => {
  return new Date()
    .toLocaleDateString('en-US', {
      day: '2-digit',
      timeZoneName: 'short',
    })
    .slice(4);
};
