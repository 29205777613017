import { useContext } from 'react';
import styled, { css } from 'styled-components';

import Badge from '../../../dashboard/components/common/base/Badge';
import Button from '../../../dashboard/components/common/base/Button';
import Icon from '../../../dashboard/components/common/base/Icon';
import Text from '../../../dashboard/components/common/base/Text';
import Tooltip from '../../../dashboard/components/common/base/Tooltip';
import DisplayDate from '../../../dashboard/components/common/DisplayDate';
import StyledEllipsisOverflow from '../../../dashboard/components/common/helpers/EllipsisOverflow';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import { Status } from '../../../dashboard/components/common/Status';
import Table from '../../../dashboard/components/common/Table';
import { rejection_cause_labels } from '../../../dashboard/utils/rejection-causes';
import { ConsoleEntriesContext } from '../context/ConsoleEntriesContext';
import { ConsoleResourceContext } from '../context/ConsoleResourceContext';
import { ConsoleSidebarContext } from '../context/ConsoleSidebarContext';

const StyledTableWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.surface.base.surface};
    border-bottom: ${theme.border};
  `,
);
const ConsoleEntriesTable: React.FC = () => {
  const { entries, sendRequest, retryEvent } = useContext(ConsoleEntriesContext);
  const { selectEventOrRequest, selected_entry, editDestination } =
    useContext(ConsoleSidebarContext);
  const { webhook } = useContext(ConsoleResourceContext);

  const rows = entries?.map((entry) => ({
    id: entry.id,
    selectable: true,
    selected: selected_entry?.id === entry.id,
    fields: [
      {
        clickable_area: true,
        element: (
          <div>
            <DisplayDate date={entry.created_at} />
          </div>
        ),
      },
      'status' in entry ? (
        <div>
          <Status {...entry} />
        </div>
      ) : (
        {
          clickable_area: true,
          element:
            entry.rejection_cause && entry.rejection_cause !== 'NO_CONNECTION' ? (
              <Tooltip tooltip={rejection_cause_labels[entry.rejection_cause]}>
                <Badge danger>
                  <Icon icon="error" left />
                  <StyledEllipsisOverflow>Error</StyledEllipsisOverflow>
                </Badge>
              </Tooltip>
            ) : (
              <div>
                <Badge icon="success_circle" neutral>
                  Accepted
                </Badge>
              </div>
            ),
        }
      ),

      {
        clickable_area: true,
        element: (
          <Text mono w={100}>
            <StyledEllipsisOverflow>{JSON.stringify(entry.data?.body)}</StyledEllipsisOverflow>
          </Text>
        ),
      },
      {
        clickable_area: false,
        element: (
          <Div flex={{ justify: 'flex-end', align: 'center' }}>
            {!('webhook_id' in entry) && webhook && (
              <Tooltip tooltip="Send to a destination" placement="bottom-end">
                <Button
                  m={{ y: -1.5 }}
                  invisible
                  small
                  icon="send"
                  onClick={(e) => {
                    e.preventDefault();
                    sendRequest(entry.id, webhook);
                  }}
                />
              </Tooltip>
            )}
            {!webhook && (
              <Tooltip tooltip="Set a destination and send" placement="bottom-end">
                <Button
                  m={{ y: -1.5 }}
                  invisible
                  small
                  icon="send"
                  onClick={(e) => {
                    e.preventDefault();
                    editDestination();
                  }}
                />
              </Tooltip>
            )}
            {'webhook_id' in entry && webhook && (
              <Tooltip tooltip="Resend to destination" placement="bottom-end">
                <Button
                  m={{ y: -1.5 }}
                  invisible
                  small
                  icon="retry"
                  onClick={(e) => {
                    e.preventDefault();
                    retryEvent(entry.id);
                  }}
                />
              </Tooltip>
            )}
          </Div>
        ),
      },
    ],
  }));

  if (!entries || !rows) {
    return null;
  }

  return (
    <Div m={{ b: 6 }}>
      <StyledTableWrapper>
        <Table
          onRowSelected={(id) => {
            const entry = entries.find((entry) => entry.id === id);
            if (!entry) {
              return;
            }
            selectEventOrRequest(id, entry);
          }}
          headers={['Time', 'Status', 'Body', '']}
          widths={[
            { min: 140, max: 140 },
            { min: 174, max: 174 },
            { min: 100 },
            { min: 28, max: 28 },
          ]}
          rows={rows}
        />
      </StyledTableWrapper>
      {entries.length === 250 && (
        <Div flex={{ align: 'center' }} m={{ t: 4 }} p={{ x: 4 }}>
          <Icon icon="info" small muted left />
          <Text muted>The console displays the latest 250 events.</Text>
        </Div>
      )}
    </Div>
  );
};

export default ConsoleEntriesTable;
