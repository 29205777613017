import { colors } from './colors';

/**
 * Anything commented out is a new color token. Figma color naming matches the comments.
 */

const color_tokens = {
  // background colors
  surface: {
    base: {
      background: colors.dark_levels[1], // tan-bg
      surface: colors.dark_levels[0], // bg-0
      variant_surface: colors.dark_levels[7], // bg-3
      variant_surface_2: colors.dark_levels[3], // bg-2
      variant_surface_3: colors.dark_levels[2], // bg-1
      background_elevated: colors.dark_levels[2], // bg-elevated
      background_contrast: colors.white, // bg-contrast - new color token
      skeleton: colors.dark_levels[4],
      primary: colors.blue[500], // primary-blue-bg
      success: colors.green[600], // no match - used on console
      green_histogram: colors.green[700], // green-histogram-bg - new color token
      warning: colors.yellow[600], // primary-yellow-bg
      danger: colors.red[600], // primary-red-bg
      red_histogram: colors.red[400], // red-histogram-bg - new color token
      disabled: colors.dark_levels[1],
      backdrop: `rgba(0, 0, 0, 0.5)`,
      // States
      hover: {
        neutral: colors.dark_levels[4], // bg-0-hover
        neutral_variant: colors.dark_levels[3],
        primary: colors.blue[400], // primary-blue-bg-hover
        danger: colors.red[400], // primary-red-bg-hover
      },
      active: {
        neutral: colors.dark_levels[1],
        neutral_variant: colors.dark_levels[2],
        primary: colors.blue[500],
        danger: colors.red[500],
      },
    },
    chart: {
      primary: colors.blue[400],
      danger: colors.red[400],
      success: colors.green[400],
    },
    container: {
      neutral: colors.dark_levels[3],
      primary: colors.blue[800], // blue-badge-bg
      success: colors.green[800], // success-badge-bg
      warning: colors.yellow[900], // caution-badge-bg
      danger: colors.red[800], // danger-badge-bg
      highlight: colors.yellow[800],
      // States
      hover: {
        neutral: colors.dark_levels[3],
        primary: colors.blue[700], // blue-badge-bg-hover
        success: colors.green[600],
        warning: colors.yellow[800],
        danger: colors.red[700],
      },
      active: {
        neutral: colors.dark_levels[2],
        primary: colors.blue[800],
        success: colors.green[800],
        warning: colors.yellow[900],
        danger: colors.red[800],
      },
    },
  },
  // foreground colors
  on: {
    neutral: {
      primary_neutral: colors.stone[100], // neutral-fg-1
      secondary_neutral: colors.stone[700], // neutral-fg-3
      tertiary_neutral: colors.stone[500], // neutral-fg-2
      primary: colors.blue[300], // primary-fg
      danger: colors.red[300], // danger-fg
      warning: colors.yellow[300],
      success: colors.green[300],
      disabled: colors.stone[800], // disabled-fg
      loading: colors.neutral[700],
    },
    code: {
      boolean: colors.blue[300], // code-fg-boolean
      string: colors.green[300], // code-fg-string
      number: colors.yellow[300], // code-fg-integer
      null: colors.red[300], // code-fg-null
    },
    hue: {
      primary: colors.white, // on-hue-fg
      success: colors.neutral[100],
      warning: colors.black,
      danger: colors.neutral[100],
      contrast: colors.black, // on-contrast-fg - new color token
    },
    hue_container: {
      primary: colors.blue[200], // primary-badge-fg
      primary_variant: colors.blue[400],
      secondary: colors.blue[400],
      success: colors.green[200], // success-badge-fg
      warning: colors.yellow[300], // caution-badge-fg
      danger: colors.red[200], // danger-badge-fg
    },
  },
  outline: {
    neutral: colors.dark_levels[7], // neutral-outline-1
    neutral_2: colors.dark_levels[9], // neutral-outline-2
    contrast_line: colors.white, // contrast-line
    primary: colors.blue[600], // primary-badge-outline
    success: colors.green[600], // success-badge-outline
    warning: colors.yellow[800], // caution-badge-outline
    danger: colors.red[700], // danger-badge-outline
    // States
    hover: {
      neutral: colors.neutral[800],
      neutral_variant: colors.neutral[800],
      container_primary: colors.blue[500], // primary-badge-outline-hover
    },
    focus: {
      primary: colors.blue[300], // focused-outline
    },
    active: {
      neutral: colors.neutral[800],
      container_primary: colors.blue[600],
    },
  },
};

const elevation = {
  '1': '0px 1px 0px 0px rgba(0, 0, 0, 0.25)',
  '2': '0px 1px 0px 0px rgba(0, 0, 0, 0.4), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset',
  '3': '0px 8px 24px 0px rgba(0, 0, 0, 0.25)',
};

// Shadows replace elevation tokens
const shadows = {
  primary: {
    base: '0px 0.75px 0px 0px rgba(255, 255, 255, 0.24) inset, 0px 0px 0px 1px #0036A3, 0px 2px 4px 0px rgba(0, 0, 0, 0.64);',
    muted: '0px 1px 2px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.08);',
    focused:
      '0px 1px 2px 0px rgba(0, 0, 0, 0.50), 0px 0px 0px 1px #0036A3, 0px 0.75px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 0px 0px 2px #FFF, 0px 0px 0px 4px #668FE0;',
  },
  neutral: {
    base: '0px 0px 0px 1px #FFFFFF3D;, 0px -1px 0px 0px #FFFFFF0A;',
    muted: '0px 1px 0px 0px rgba(0, 0, 0, 0.4), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset',
    focused: '0px 8px 24px 0px rgba(0, 0, 0, 0.25)',
  },
  disabled: {
    base: '0px 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.08);',
  },
  danger: {
    base: '0px 1px 2px 0px #000000A3 inset, 0px 0px 0px 1px #CC2314;',
  },
  input: {
    neutral: '0px 2px 4px 0px #000000A3 inset, 0px 0px 0px 1px #FFFFFF3D;',
  },
};

export default {
  colors: color_tokens,
  elevation,
  shadows,
};
