import { Component, PropsWithChildren } from 'react';
import { User } from '../../../../typings/User.interface';
import Alert from '../../dashboard/components/common/base/Alert';
import Button from '../../dashboard/components/common/base/Button';
import Container from '../../dashboard/components/common/base/Container';
import Text from '../../dashboard/components/common/base/Text';
import Sentry from './Sentry';

class ErrorBoundary extends Component<
  PropsWithChildren<{ user?: User; LogRocket?: any }>,
  { error?: Error }
> {
  constructor(props: any) {
    super(props);
  }

  state: any = {
    error: null,
  };

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      if (this.props.LogRocket) {
        scope.setExtra('sessionURL', this.props.LogRocket.sessionURL);
      }

      if (this.props.user) {
        scope.setUser(this.props.user);
      }
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
    if (this.props.LogRocket) {
      this.props.LogRocket.captureException(error);
    }
    this.setState({ error });
  }

  render() {
    return this.state.error ? (
      <Container medium p={8}>
        <Text bold size="l" m={{ b: 2 }}>
          Something went wrong...
        </Text>
        <Text as="p" muted m={{ b: 4 }}>
          The technical team was notified and will look into it!
        </Text>
        {this.state.error.message && (
          <Alert danger inline>
            {this.state.error.message}
          </Alert>
        )}
        <Button m={{ y: 5 }} primary onClick={() => window.location.reload()}>
          Reload
        </Button>
      </Container>
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
